<h1 mat-dialog-title>Terminar</h1>

<mat-dialog-content>
    <span>
        <p>¿Está seguro de que quiere terminar esta publicación?</p>
        <p>La publicación no será visible.</p>

    </span>
</mat-dialog-content>

<button mat-button [mat-dialog-close]="true">Terminar</button>
<button mat-button [mat-dialog-close]="false" cdkFocusInitial>Cancelar</button>

