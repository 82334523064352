import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Post, Single } from 'src/app/models/post';
import { User } from 'src/app/models/user';
import { DatabaseServiceService } from 'src/app/services/database-service.service';

@Component({
  selector: 'app-edit-post',
  templateUrl: './edit-post.component.html',
  styleUrls: ['./edit-post.component.scss']
})
export class EditPostComponent implements OnInit {


  public loading = false;
  public postID: string;
  public post: Post | undefined;
  public creator: User;
  public notFound = false;

  constructor(private route: ActivatedRoute, private db: DatabaseServiceService) { }

  private postNotFound(): void {
    this.loading = false;
    this.notFound = true;
  }

  private async getPost(): Promise<void> {

    this.loading = true;
    try {
      this.post = await this.db.getPostFromID(this.postID) as Post;
    } catch (e) {
      console.log('post not found');
      this.postNotFound();
      return;
    }
    if (this.post === undefined) {
      this.postNotFound();
      return;
    }
    console.log('post: ', this.post);
    const creator = await this.db.getUserFromID(this.post.creator);
    this.creator = creator;
    this.loading = false;
  }

  ngOnInit(): void {
    this.route.params.subscribe(async params => {
      this.postID = params.id;
      await this.getPost();
    });
  }

}
