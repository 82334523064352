<div [ngClass]="{'blured': loading}">


  <!--LOADING SPINNER-->
  <div *ngIf="loading" class="loading-area" fxLayout="row" fxLayoutAlign="center center">
    <mat-spinner></mat-spinner>
  </div>


  <!--nav bar-->
  <app-nav-bar></app-nav-bar>

  <!--CONTENIDO-->
  <div fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-around stretch" fxLayout="column" fxLayoutAlign="start center"
    class="container">
    <div fxLayoutAlign.gt-sm="space-around stretch" fxLayout="column" fxLayoutAlign="start center" class="box_container"
      fxFlex="60%" fxFlex.lt-md="90%">
      <div class="payment-info" fxLayout="column" fxLayoutAlign="space-evenly center">
        <div *ngIf="payingWithPromoCode" style="width: 100%">
          <button mat-icon-button color="primary" (click)="backToSelectPaymentMethod()" aria-label="back">
            <mat-icon>arrow_back</mat-icon>
          </button>
        </div>

        <h1>Paga tu publicación</h1>

        <h2 id="price">$10.000</h2>

        <div *ngIf="!payingWithPromoCode" div fxLayout="row" fxLayout.lt-md="column"
          fxLayoutAlign="space-evenly stretch" style="width: 100%;">
          <button mat-raised-button fxFlex="30%" fxFlex.lt-md="80%" class="pay-button"
            (click)="form.submit(); logPayment();"> Pagar
            con <img id="payu-image"
              src="https://colombia.payu.com/wp-content/themes/global-website/assets/src/images/payu-logo.svg"></button>

          <button mat-raised-button fxFlex="30%" fxFlex.lt-md="80%" class="pay-button" (click)="selectPromoCode()">
            código de regalo</button>

          <form #form method="post" target="_blank" [action]="payUEndpoint" hidden="true">
            <input type="text" name="merchantId" [value]="payUInfo.merchantId">
            <input type="text" name="accountId" [value]="payUInfo.accountId">
            <input type="text" name="description" [value]="payUInfo.description">
            <input type="text" name="referenceCode" [value]="payUInfo.referenceCode">
            <input type="text" name="amount" [value]="payUInfo.amount">
            <input type="text" name="tax" [value]="payUInfo.tax">
            <input type="text" name="taxReturnBase" [value]="payUInfo.taxReturnBase">
            <input type="text" name="currency" [value]="payUInfo.currency">
            <input type="text" name="signature" [value]="payUInfo.signature">
            <input type="text" name="test" [value]="payUInfo.test">
            <input name="buyerEmail" type="hidden" [value]="payUInfo.buyerEmail">
            <input name="responseUrl" type="hidden" [value]="payUInfo.responseUrl">
            <input name="confirmationUrl" type="hidden" [value]="payUInfo.confirmationUrl">
            <button mat-button color="primary" type="submit" (click)="form.submit()">Submit</button>
            <input type="submit" value="Submit" />
          </form>

        </div>

        <div *ngIf="payingWithPromoCode" div fxLayout="column" fxLayoutAlign="space-evenly center" style="width: 100%;">

          <mat-form-field appearance="outline" fxFlex="80%" style="width: 100%;">
            <mat-label>Código</mat-label>
            <input matInput type="text" [(ngModel)]="promoCode" placeholder="XXXX-XXXXX-XXXXX" fxFlex="100%"
              (ngModelChange)="formatPromoCode()" maxlength=19>
            <button *ngIf="promoCode" matSuffix mat-icon-button aria-label="Clear" (click)="promoCode=''">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>

          <button mat-raised-button color="primary" (click)="payWithCode()" class="pay-btn">Redimir</button>
        </div>

      </div>

    </div>


  </div>

</div>