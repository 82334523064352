<form class="example-form" style="width: 100%;">
    <mat-form-field appearance="outline" style="width: 100%;">
        <mat-label>Mascota</mat-label>
        <mat-select [formControl]="formControl" name="animal">

            <mat-option *ngFor="let animal of animals" [value]="animal.value"> {{animal.label}}
            </mat-option>
        </mat-select>
    </mat-form-field>

</form>