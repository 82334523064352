import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs/internal/Observable';
import { map, startWith } from 'rxjs/operators';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-breed-autocomplete',
  templateUrl: './breed-autocomplete.component.html',
  styleUrls: ['./breed-autocomplete.component.scss']
})
export class BreedAutocompleteComponent implements OnInit, OnChanges {

  @Input() cualquiera = false;
  @Input() breedFormControl: FormControl = new FormControl();
  @Input() animal = 'dog';
  @Input() initial: string | undefined = undefined;
  options: string[];
  filteredOptions: Observable<string[]>;


  @Output() breedChange = new EventEmitter();
  constructor(private utils: UtilsService) {
  }

  ngOnInit(): void {

    if (this.animal === 'dog') {
      this.breedFormControl.enable();
      this.breedFormControl.setValue('');
      this.utils.getDogBreeds().then(breeds => {
        breeds.sort();
        this.options = breeds;
        if (this.cualquiera) {
          this.options = ['cualquiera', ...this.options];
        }


        this.filteredOptions = this.breedFormControl.valueChanges.pipe(
          startWith(''),
          map(value => this._filter(value)),
        );
      });
    }
    else if (this.animal === 'cat') {
      this.breedFormControl.setValue('');
      this.breedFormControl.enable();
      this.utils.getCatBreeds().then(breeds => {

        this.options = breeds;
        if (this.cualquiera) {
          this.options = ['cualquiera', ...this.options];
        }

        this.filteredOptions = this.breedFormControl.valueChanges.pipe(
          startWith(''),
          map(value => this._filter(value)),
        );
      }).catch(err => {
        console.log(err);
      }
      );
    }
    else {
      this.breedFormControl.disable();
    }

    if (this.initial !== undefined) {
      this.breedFormControl.setValue(this.initial);
    }
    this.breedFormControl.valueChanges.subscribe(x => {
      this.breedChange.emit(x);
    });
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }


  ngOnChanges(changes: SimpleChanges): void {
    // checks again if the animal is a dog or a cat and inits the component again
    if (!changes.animal.firstChange) {
      this.ngOnInit();
    }
  }


}
