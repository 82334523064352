import { Component, OnInit } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})


export class HelpComponent implements OnInit {

  public loading = false;
  public questionList: Promise<FAQ[]>;
  constructor(private utilsService: UtilsService) {}

  ngOnInit(): void {
    this.questionList = this.utilsService.getFAQ();
  }
}

export interface FAQ{
  question: string;
  answer: string;
}
