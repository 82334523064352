import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Single } from 'src/app/models/post';

@Component({
  selector: 'app-publisher-post-card',
  templateUrl: './publisher-post-card.component.html',
  styleUrls: ['./publisher-post-card.component.scss']
})
export class PublisherPostCardComponent implements OnInit {

  @Output() edit = new EventEmitter<void>();
  @Output() pay = new EventEmitter<void>();
  @Output() finish = new EventEmitter<void>();


  @Input() post: any = {
    active: true,
    creationDate: new Date(),
    description: 'Lorem Ipsum sdaf asdf lkan dqui hfuihe uf asuidf asd fasd fas. asdl asdhjlfgasudfg asg as dgasjl iqweuis djbyui sjkdhf as.',
    litter: false,
    views: 10,
    type: 'sell',
    price: 1000,
    pet: {
      name: 'Chanda',
      sex: 'female',
      certificates: [],
      vaccines: ['rabia', 'pulgas'],
      breed: 'Airdale terrier',
      birthDate: new Date()
    }


  } as unknown as Single;
  constructor() {
  }


  payPost(): void {
    this.pay.emit();
  }

  finishPost(): void {
    this.finish.emit();
  }

  editPost(): void {
    this.edit.emit();
  }

  ngOnInit(): void {

  }

}


