<div [ngClass]="{'blured': loading}" fxLAyout="column">

    <app-nav-bar></app-nav-bar>
    <!--LOADING SPINNER-->
    <div *ngIf="loading" class="loading-area" fxLayout="row" fxLayoutAlign="center center">
        <mat-spinner></mat-spinner>
    </div>



    <div fxLayout="row" fxLayoutAlign="center center" class="container">
        <div fxLayout="column" fxFlex="80">
        <h1>Preguntas frecuentes</h1>
        <mat-accordion  multi>
            <mat-expansion-panel *ngFor="let faq of questionList | async">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <b>{{faq.question}}</b>
                </mat-panel-title>
              </mat-expansion-panel-header>
              {{faq.answer}}
            </mat-expansion-panel>
          </mat-accordion>
    </div>
</div>
</div>