import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs/internal/Observable';
import { map, startWith } from 'rxjs/operators';
import { Post, Single } from 'src/app/models/post';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-add-pet',
  templateUrl: './add-pet.component.html',
  styleUrls: ['./add-pet.component.scss']
})
export class AddPetComponent implements OnInit, OnChanges {

  breedCtrl = new FormControl('', [Validators.required]);

  petInfoForm = new FormGroup({
    sexCtrl: new FormControl('', [Validators.required]),
    birthCtrl: new FormControl('', [Validators.required]),
    descriptionCtrl: new FormControl('', [Validators.required]),
    vaccineCtrl: new FormControl(),
    priceCtrl: new FormControl('', []),
    breedCtrl: this.breedCtrl
  });

  // for vaccine autocomplete
  separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredVaccines: Observable<string[]>;
  commonVaccines: string[];

  @ViewChild('vaccineInput') vaccineInput: ElementRef<HTMLInputElement> | undefined;
  @ViewChild('myForm') form!: NgForm;
  @Input() animal = 'dog';
  @Input() myPost: Single = {
    id: '',
    adopt: false,
    creator: '',
    city: '',
    active: false,
    creationDate: new Date(),
    expirationDate: new Date(),
    description: '',
    payed: false,
    litter: false,
    views: 0,
    type: 'sell',
    price: 0,
    animal: 'dog',
    pet: {
      sex: '',
      certificates: [],
      vaccines: [],
      breed: '',
      birthDate: new Date()
    },
    picture: 'https://images.dog.ceo/breeds/wolfhound-irish/n02090721_1688.jpg'

  };

  @Output() isValidForm = new EventEmitter<boolean>();

  post = this.myPost;
  allBreeds = new Set<string>();
  showPrice = false;

  constructor(private utils: UtilsService) {
    this.utils.getDogVaccines().then(vac => {
      this.commonVaccines = vac;
      this.filteredVaccines = this.petInfoForm.controls.vaccineCtrl.valueChanges.pipe(
        startWith(null),
        map((vacc: string | null) => (vacc ? this._filter(vacc) : this.commonVaccines.slice())),
      );
    });

    this.petInfoForm.valueChanges.subscribe(x => {
      this.updateObject(x);
    });

    this.petInfoForm.valueChanges.subscribe(x => {
      this.isValidForm.emit(this.petInfoForm.valid);
    });

  }


  updateObject(f: any): void {
    this.myPost.description = f.descriptionCtrl;
    this.myPost.pet.sex = f.sexCtrl;
    this.myPost.pet.birthDate = f.birthCtrl;
    this.myPost.price = f.priceCtrl;
    this.myPost.pet.breed = f.breedCtrl;
    this.myPost.pet.vaccines = this.post.pet.vaccines;
  }

  // Methods to handle vaccines auto complete
  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    if (value) {
      this.post.pet.vaccines.push(value);
    }

    // Clear the input value
    if (event.input) {
      event.input.value = '';
    }

    this.petInfoForm.controls.vaccineCtrl.setValue(null);
  }

  remove(vaccine: string): void {
    const index = this.post.pet.vaccines.indexOf(vaccine);

    if (index >= 0) {
      this.post.pet.vaccines.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.post.pet.vaccines.push(event.option.viewValue);
    this.vaccineInput!.nativeElement.value = '';
    this.petInfoForm.controls.vaccineCtrl.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.commonVaccines.filter(vaccine => vaccine.toLowerCase().includes(filterValue));
  }

  public adopt(): void {
    this.petInfoForm.controls.priceCtrl.setValue(0);
    this.post.price = 0;
    this.myPost.adopt = true;
    this.showPrice = false;
    console.log('setting adopt to true', this.post);
  }

  public sell(): void {
    this.petInfoForm.controls.priceCtrl.setValue(0);
    this.post.price = 0;
    this.myPost.adopt = false;
    this.showPrice = true;
  }

  async ngOnChanges(): Promise<void> {
    console.log(this.animal);
    if (this.animal === 'dog') {
      const breeds = await this.utils.getDogBreeds();
      breeds.forEach(b => this.allBreeds.add(b));
    }
    else {
      const breeds = await this.utils.getCatBreeds();
      breeds.forEach(b => this.allBreeds.add(b));
    }
    this.petInfoForm.get('breedCtrl')?.markAsUntouched();
    this.petInfoForm.get('breedCtrl')?.valueChanges.subscribe(x => {
      if (!this.allBreeds.has(x)) {
        this.petInfoForm.get('breedCtrl')?.setErrors({ invalid: true });
        this.petInfoForm.get('breedCtrl')?.markAsTouched();
      }
    });
  }

  ngOnInit(): void {

  }

}
