<div *ngIf="loading" class="loading-area" fxLayout="row" fxLayoutAlign="center center">
    <mat-spinner></mat-spinner>
    <mat-card>
        <p><b>{{message}}</b></p>
    </mat-card>
</div>
<div fxLayout="column" fxLayoutAlign="center center" [ngClass]="{'blured': loading}">
    <form fxLayout="column" [formGroup]="petInfoForm" style="width: 500px;">
        <div class="cover-image-container" (mouseover)="hoveringCoverImage=true" (mouseout)="hoveringCoverImage=false"
            (click)="coverFileInput.click()">
            <img [src]="myPost.picture" class="edit-cover-image">
            <mat-icon *ngIf="hoveringCoverImage" class="centered edit-icon">edit</mat-icon>
            <input #coverFileInput type="file" accept=".jpg,.jpeg,.png,.webp" (change)="editCoverPhoto($event)"
                style="display:none;" />
        </div>
        <app-breed-autocomplete [animal]="myPost.animal" [initial]="myPost.pets[0].breed" [formControl]="breedCtrl">
        </app-breed-autocomplete>

        <mat-form-field appearance="outline">
            <mat-label>Fecha de nacimiento</mat-label>
            <input matInput [matDatepicker]="picker" formControlName="birthCtrl" name="birthDate" ngDefaultControl>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

        <!--Vacunas-->
        <mat-form-field class="example-chip-list" appearance="outline">
            <mat-label>Vacunas</mat-label>
            <mat-chip-list #chipList>
                <mat-chip *ngFor="let vaccine of selectedVaccines" (removed)="remove(vaccine)">
                    {{vaccine}}
                    <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input placeholder="Otra vacuna..." #vaccineInput formControlName="vaccineCtrl" [matAutocomplete]="auto"
                    [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    (matChipInputTokenEnd)="add($event)">
            </mat-chip-list>
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                <mat-option *ngFor="let vaccine of filteredVaccines | async" [value]="vaccine">
                    {{vaccine}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <mat-form-field appearance="outline" style="height: 100%;">
            <mat-label>Descripción</mat-label>
            <textarea matInput formControlName="descriptionCtrl" name="description" style="height: 100%;"
                rows="11"></textarea>
        </mat-form-field>


        <!------------------------>

        <div fxLayout="column" fxLayoutAlign="space-between stretch">
            <div *ngFor="let item of [].constructor(numberOfChildren); let i = index" fxLayout="row"
                fxLayoutAlign="space-between stretch" style="margin-bottom: 10px;">
                <div fxLayout="column" fxLayoutAlign="center start">
                    <mat-form-field appearance="outline" class="child-info-field">
                        <mat-label>Sexo</mat-label>
                        <mat-select [formControl]="$any(children[i].controls.sexCtrl)" name="sex">
                            <mat-option value="male">
                                Macho
                            </mat-option>

                            <mat-option value="female">
                                Hembra
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="child-info-field">
                        <mat-label>Precio</mat-label>
                        <span matSuffix>COP &nbsp;</span>

                        <input matInput type="number" [formControl]="$any(children[i].controls.priceCtrl)"
                            name="description">
                    </mat-form-field>
                    <button mat-mini-fab color="warn" (click)="removeChild(i)" #tooltip="matTooltip"
                        matTooltip="Eliminar cria" [matTooltipPosition]="'right'" matTooltipHideDelay="1000"
                        class="remove-child-btn">
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>

                <div fxLayout="column" fxLayoutAlign="center center">
                    <img *ngIf="petImages[i] != ''" [src]="petImages[i]" width="100">
                    <button mat-stroked-button color="primary" type="button" (click)="coverFileInput.click()"
                        class="secondary-btn">
                        <span *ngIf="petImages[i] == '' && petImages[i] != undefined">Agregar foto</span>
                        <span *ngIf="!(petImages[i] == '' && petImages[i] != undefined)">Cambiar foto</span>

                        <input #coverFileInput type="file" accept=".jpg,.jpeg,.png,.webp"
                            (change)="onSelectSingleFile($event, i)" style="display:none;"
                            [formControl]="$any(children[i].controls.imageCtrl)" />
                    </button>
                </div>
            </div>

            <button type='button' mat-stroked-button class="secondary-btn" style="width: 200px;" (click)="addChild()">
                Agregar cria a la camada
            </button>
        </div>

        <button mat-raised-button color="primary" type="submit" class="primary-btn" style="width: 200px;"
            [disabled]="!isValidForm" (click)="savePost()">
            Guardar</button>
    </form>

</div>