import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { User } from 'src/app/models/user';
import colombia from 'src/assets/json/Colombia.json';

@Component({
  selector: 'app-seller-info',
  templateUrl: './seller-info.component.html',
  styleUrls: ['./seller-info.component.scss']
})
export class SellerInfoComponent implements OnInit {


  @Input() user: User;
  @Output() continueEvent = new EventEmitter<void>();


  userInfoForm: FormGroup;
  errorMessage = '';
  allCities: Set<string> = new Set();

  constructor() { }

  /**
   * Checks the value of the inputs and emits a value if the form passes all validations
   */
  public submit(): void {

    this.user.city = this.userInfoForm.get('cityCtrl')?.value;
    this.user.name = this.userInfoForm.get('nameCtrl')?.value;
    this.user.phone = this.userInfoForm.get('phoneCtrl')?.value;
    this.user.neighbourhood = this.userInfoForm.get('neighbourhoodCtrl')?.value;

    this.continueEvent.emit();

  }


  private getAllCities(): Set<string> {
    const ans: Set<string> = new Set();
    colombia.forEach((departamento) => {
      departamento.ciudades.forEach((ciudad) => {
        ans.add(ciudad);
      });
    });
    return ans;
  }


  ngOnInit(): void {

    this.allCities = this.getAllCities();
    console.log(this.user);
    this.userInfoForm = new FormGroup({
      nameCtrl: new FormControl(this.user.name, [Validators.required]),
      phoneCtrl: new FormControl(this.user.phone, [Validators.required, Validators.pattern('[0-9 ]{10}')]),
      cityCtrl: new FormControl(this.user.city, [Validators.required]),
      neighbourhoodCtrl: new FormControl(this.user.neighbourhood, [Validators.required]),
    });

    this.userInfoForm.get('cityCtrl')?.valueChanges.subscribe((value) => {
      console.log(this.userInfoForm.get('cityCtrl'));
      if (!this.allCities.has(value)) {
        this.userInfoForm.get('cityCtrl')?.setErrors({ invalid: true });
        this.userInfoForm.get('cityCtrl')?.markAsTouched();
      }
    });
  }

}
