<form fxLayout="column" class="form-container" [formGroup]="petInfoForm">
    <ng-template matStepLabel>informacion basica</ng-template>

    <h1>Mascota</h1>

    <div fxLayout.gt-sm="row" fxLayout="column" fxLayoutAlign="space-between stretch">

        <div fxLayout="column" fxLayoutAlign="space-between stretch" fxFlex="45%">
            <app-breed-autocomplete [animal]="animal" [formControl]="breedCtrl" style="width: 100%;"
                [cualquiera]="false" (breedChange)="breedCtrl.setValue($event)">
            </app-breed-autocomplete>
            <div *ngIf="petInfoForm.get('breedCtrl')!.errors && 
                        (petInfoForm.get('breedCtrl')!.dirty || petInfoForm.get('breedCtrl')!.touched)">
                <small class="text-danger" *ngIf="petInfoForm.get('breedCtrl')!.hasError('required')">
                    Este campo es requerido
                </small>
                <small class="text-danger" *ngIf="petInfoForm.get('breedCtrl')!.hasError('invalid')!">
                    Debe seleccionar una raza que se encuentre en el listado.
                </small>
            </div>


            <mat-form-field appearance="outline">
                <mat-label>Sexo</mat-label>
                <mat-select formControlName="sexCtrl" name="sex">
                    <mat-option value="male">
                        Macho
                    </mat-option>

                    <mat-option value="female">
                        Hembra
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Fecha de nacimiento</mat-label>
                <input matInput [matDatepicker]="picker" formControlName="birthCtrl" name="birthDate">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>

            <!--Vacunas-->
            <mat-form-field class="example-chip-list" appearance="outline">
                <mat-label>Vacunas</mat-label>
                <mat-chip-list #chipList>
                    <mat-chip *ngFor="let vaccine of post.pet.vaccines" (removed)="remove(vaccine)">
                        {{vaccine}}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                    <input placeholder="Otra vacuna..." #vaccineInput formControlName="vaccineCtrl"
                        [matAutocomplete]="auto" [matChipInputFor]="chipList"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="add($event)">
                </mat-chip-list>
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                    <mat-option *ngFor="let vaccine of filteredVaccines | async" [value]="vaccine">
                        {{vaccine}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>


            <mat-radio-group aria-label="Select an option" fxLayout="column" class="radio-group">
                <mat-radio-button (click)="adopt()" [checked]="true" value="1" class="radio">
                    Poner en adopción
                </mat-radio-button>
                <mat-radio-button (click)="sell()" value="2" class="radio">
                    Vender
                </mat-radio-button>
            </mat-radio-group>

            <mat-form-field appearance="outline" *ngIf="showPrice">
                <mat-label>Precio</mat-label>
                <span matSuffix>COP &nbsp;</span>

                <input matInput type="number" formControlName="priceCtrl" name="description">
            </mat-form-field>
        </div>

        <div fxLayout="column" fxLayoutAlign="space-around stretch" fxFlex="45%">

            <mat-form-field appearance="outline" class="no-padding" style="height: 100%;">
                <mat-label>Descripción</mat-label>
                <textarea matInput formControlName="descriptionCtrl" name="description" style="height: 100%;"
                    rows="24"></textarea>
            </mat-form-field>
        </div>

    </div>


</form>