import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Post } from 'src/app/models/post';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { DatabaseServiceService } from 'src/app/services/database-service.service';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { AnalyticsService } from 'functions/services/analytics.service';

@Component({
  selector: 'app-favorites',
  templateUrl: './favorites.component.html',
  styleUrls: ['./favorites.component.scss']
})
export class FavoritesComponent implements OnInit {

  public loading = true;
  public favoritePosts: Post[];
  private user: User;

  constructor(private db: DatabaseServiceService,
    private auth: AuthService,
    private router: Router,
    private analytics: AnalyticsService) {
    this.auth.user$.subscribe(async u => {
      this.user = u;
      this.loading = true;
      this.favoritePosts = await this.getFavorites();
      this.loading = false;
    });
  }

  private async getFavorites(): Promise<Post[]> {
    return await this.db.getFavoritePosts(this.user);
  }

  public isFavorite(postId: string): boolean {
    if (this.user === null) {
      return false;
    }
    const ans = this.user.favorites.indexOf(postId) !== -1;
    return ans;
  }

  public async setAsNotFavorite(postId: string): Promise<void> {
    this.loading = true;
    this.analytics.setAsNotFavorite(postId);
    await this.db.setAsNotFavorite(postId, this.user.uid);
    this.loading = false;

  }

  public goToPost(postId: string): void {
    this.router.navigate(['/post_info', postId]);
  }


  public async setAsFavourite(id: string): Promise<void> {
    this.loading = true;

    this.analytics.setAsFavorite(id);

    await this.db.setAsFavourite(id, this.user.uid);
    this.loading = false;
  }


  ngOnInit(): void {
  }

}
