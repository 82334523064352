import { Component, OnInit } from '@angular/core';
import { DatabaseServiceService } from 'src/app/services/database-service.service';


export interface CodeInfo {
  available: boolean;
  expiration: Date;
}

@Component({
  selector: 'app-generate-codes',
  templateUrl: './generate-codes.component.html',
  styleUrls: ['./generate-codes.component.scss']
})
export class GenerateCodesComponent implements OnInit {

  private alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890';
  private codeLength = 16;
  public codes: string[] = [];
  public today = new Date();
  public expirationDate = new Date();
  // in days
  public codeDuration = 90;

  public loading = false;

  public numberOfCodes = 1;

  constructor(private db: DatabaseServiceService) {
    this.expirationDate.setDate(this.today.getDate() + this.codeDuration);

  }

  ngOnInit(): void {
  }

  public async addCodes(): Promise<void> {

    this.loading = true;
    const codeInfo: CodeInfo = { available: true, expiration: this.expirationDate };
    for (let i = 0; i < this.numberOfCodes; ++i) {
      try {
        const newCode = this.generateCode();
        this.db.addCode(this.unformat(newCode), codeInfo);
        this.codes.push(newCode);
      }
      catch (error) {
        console.log(error);
      }
    }

    this.loading = false;
  }

  public unformat(code: string): string {
    return code.split('-').join('');
  }

  public generateCode(): string {

    let code = '';

    for (let i = 0; i < this.codeLength; ++i) {
      if (i % 4 === 0 && i !== 0) {
        code += '-';
      }
      code += (this.alphabet[Math.floor(Math.random() * this.alphabet.length)]);
    }

    return code;


  }

}
