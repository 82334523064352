<div [ngClass]="{'blured': loading}">


    <!--LOADING SPINNER-->
    <div *ngIf="loading" class="loading-area" fxLayout="row" fxLayoutAlign="center center">
        <mat-spinner></mat-spinner>
    </div>
    <app-nav-bar></app-nav-bar>

    <!--POSTS-->
    <div class="container">
        <h1>Mis publicaciones</h1>
        <p>A continuación encuentras tus publicaciones.</p>
        <p>Únicamente las publicaciones que haz pagado serán vistas por otros usuarios.</p>

    </div>
    <div fxLayout="row wrap" fxLayoutGap="16px grid" fxLayoutGap.lt-sm="30px grid" class="container">

        <div fxFlex="25%" fxFlex.xs="90%" fxFlex.sm="50%" *ngFor="let post of myPosts">
            <app-publisher-post-card [post]="post" (pay)="payPost(post.id)" (finish)="openDialog(post.id)"
                (edit)="editPost(post.id)">
            </app-publisher-post-card>
        </div>
    </div>
</div>