import { Component, HostListener, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatListOption, MatSelectionList, MatSelectionListChange } from '@angular/material/list';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { AnalyticsService } from 'functions/services/analytics.service';
import { filter } from 'rxjs/operators';
import { Filters } from 'src/app/models/filters';
import { Post } from 'src/app/models/post';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { DatabaseServiceService } from 'src/app/services/database-service.service';
import { UtilsService } from 'src/app/services/utils.service';
import { BreedsDialogComponent } from '../breeds-dialog/breeds-dialog.component';
import { CityDialogComponent } from '../city-dialog/city-dialog.component';

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss']
})
export class SearchResultsComponent implements OnInit {

  public innerWidth: number;
  public lastWidth: number;
  public openDrawer = false;
  public loading = false;

  public selectedCity: string | undefined;
  public commonCities: string[];

  public onlyAdopt = false;
  public selectedBreeds: string[] = [];
  public commonDogBreeds: string[] = [];
  public commonCatBreeds: string[] = [];

  public selectedSex = '';
  public selectedAge: any | undefined;

  public selectedAnimal: string | undefined;

  public minPrice = 0;
  public maxPrice: number;

  public posts: Post[];
  private user: User;

  constructor(private utils: UtilsService,
    private auth: AuthService,
    private db: DatabaseServiceService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private analytics: AnalyticsService) {

    this.loading = true;
    this.commonCities = utils.getCommonCities();
    // this.commonDogBreeds = utils.getCommonDogBreeds();
    // this.commonCatBreeds = utils.getCommonCatBreeds();

    this.auth.user$.subscribe(u => {
      this.user = u;
      // console.log(this.user);

    });
  }

  public changedSelection(selected: MatListOption[]): void {
    console.log(selected.map(o => o.value));
    this.selectedBreeds = selected.map(o => o.value);
    this.search();
  }

  public async setAsFavourite(id: string): Promise<void> {
    const filters = this.generateFilters();
    if (this.user === null) {
      const navigationExtras: NavigationExtras = {
        state: {
          filters,
          action: 'setAsFavorite',
          postId: id
        },
      };
      console.log('filters before navigation: ', navigationExtras);
      this.router.navigate(['/login'], navigationExtras);
      return;
    }
    else {
      this.loading = true;
      await this.db.setAsFavourite(id, this.user.uid);
      this.loading = false;
    }
  }


  private generateFilters(): any {
    const filters = {
      animal: this.selectedAnimal,
      sex: this.selectedSex,
      maxPrice: this.maxPrice,
      breed: JSON.stringify(this.selectedBreeds),
      city: this.selectedCity,
      minAgeMonths: this.selectedAge !== undefined && this.selectedAge.minAgeMonths !== undefined ? this.selectedAge.minAgeMonths : 0,
      maxAgeMonths: this.selectedAge !== undefined && this.selectedAge.maxAgeMonths !== undefined ? this.selectedAge.maxAgeMonths : 1000,
      adopt: this.onlyAdopt === true ? this.onlyAdopt : undefined
    };
    if (this.selectedCity === 'cualquiera') {
      filters.city = undefined;
    }

    console.log('filters: ', filters);
    return filters;
  }

  public async search(): Promise<void> {

    this.loading = true;
    console.log(this.selectedAge);
    const filters: any = this.generateFilters();
    this.delay(1000).then(() => { this.loading = false; });
    await this.router.navigate(['/search'], { queryParams: filters });

  }

  public isFavorite(postId: string): boolean {
    if (this.user === null) {
      return false;
    }
    const ans = this.user.favorites.indexOf(postId) !== -1;
    return ans;
  }

  public async setAsNotFavorite(postId: string): Promise<void> {
    if (this.user === null) {
      this.router.navigate(['/login']);
      return;
    }
    else {
      this.loading = true;
      await this.db.setAsNotFavorite(postId, this.user.uid);
      this.loading = false;
    }

  }

  public async chooseBreed(): Promise<void> {
    const dialogRef = this.dialog.open(BreedsDialogComponent, {
      data: { animal: this.selectedAnimal },
      width: '400px',
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result:`, result);
      this.selectedBreeds.push(result.breed);
      this.analytics.searchBreed(result.animal, result.breed);
      if (result.animal === 'dog') {
        this.commonDogBreeds.push(result.breed);
        this.search();
      }
      else if (result.animal === 'cat') {
        this.commonCatBreeds.push(result.breed);
        this.search();
      }
    });
  }

  public async chooseCity(): Promise<void> {
    const dialogRef = this.dialog.open(CityDialogComponent, {
      width: '400px',
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (this.commonCities.indexOf(result) === -1 && result !== undefined) {
        this.commonCities.push(result);
      }
      if (result !== undefined) {
        this.selectedCity = result;
      }
    });
  }

  public selectDog(): void {
    if (this.selectedAnimal === 'dog') {
      return;
    }
    this.selectedAnimal = 'dog';
    this.selectedBreeds = [];
    this.search();
  }
  public selectCat(): void {
    if (this.selectedAnimal === 'cat') {
      return;
    }
    this.selectedAnimal = 'cat';
    this.selectedBreeds = [];
    this.search();
  }

  public goToPost(postId: string): void {
    this.router.navigate(['/post_info', postId]);
  }

  public toggleDrawer(): void {
    this.openDrawer = !this.openDrawer;
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.innerWidth = window.innerWidth;
    this.openDrawer = this.innerWidth > 960;

    const allCatBreeds = await this.utils.getCatBreeds();
    const allDogBreeds = await this.utils.getDogBreeds();


    // params: sex, age, city, breeds, minPrice, maxPrice, type (adopt, sell)
    this.activatedRoute.queryParams.subscribe((params: any) => {
      console.log(params);
      let selectedBreedsArray: string[] = [];
      if (params.breed !== undefined) {
        console.log(params.breed);
        selectedBreedsArray = JSON.parse(params.breed);
      }

      let filters: Filters = { breed: selectedBreedsArray };
      if (params.sex) {
        filters = { ...filters, sex: params.sex };
      }
      if (params.maxPrice) {
        filters = { ...filters, maxPrice: filters.maxPrice };
      }
      if (params.city) {
        filters = { ...filters, city: params.city };
      }
      if (params.animal) {
        filters = { ...filters, animal: params.animal };
      }
      if (params.minAgeMonths) {
        filters = { ...filters, minAgeMonths: params.minAgeMonths };
      }
      if (params.maxAgeMonths) {
        filters = { ...filters, maxAgeMonths: params.maxAgeMonths };
      }
      if (params.adopt) {
        filters = { ...filters, adopt: params.adopt === 'true' };
      }

      console.log('filters', filters);
      // query using parameters
      this.db.queryPosts(filters).then(posts => {
        this.posts = posts;
        console.log(posts);
        this.loading = false;
      });

      // populate selected information
      this.selectedSex = params.sex;
      this.selectedBreeds = selectedBreedsArray;
      this.maxPrice = params.maxPrice;
      this.selectedCity = params.city;
      this.selectedAnimal = params.animal;
      console.log('animal', this.selectedAnimal);

      // check if the new breed belongs to a dog or a cat and add it to common breeds array
      for (let i = 0; i < this.selectedBreeds.length; ++i) {
        const breed = this.selectedBreeds[i];
        const isCommonDog = this.commonDogBreeds.indexOf(breed) !== -1;
        const isCommonCat = this.commonCatBreeds.indexOf(breed) !== -1;

        const isDog = allDogBreeds.indexOf(breed) !== -1;
        const isCat = allCatBreeds.indexOf(breed) !== -1;

        if (isDog && !isCommonDog) {
          this.commonDogBreeds.push(breed);
        }
        if (isCat && !isCommonCat) {
          this.commonCatBreeds.push(breed);
        }
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  public onResize(event): void {
    this.innerWidth = window.innerWidth;
    console.log('window resize event: ', event);
    console.log('winow: ', window);
    if (this.innerWidth < 960 && this.innerWidth !== this.lastWidth) {
      this.openDrawer = true;
      this.lastWidth = this.innerWidth;
    }
  }

  delay(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}

