<app-title-bar></app-title-bar>

<div [ngClass]="{'loading': loading}" fxLayoutAlign="center center" fxFlexFill class="main-div">

  <div fxFlex.gt-sm="60" fxFlex="90" fxLayout="column" fxLayoutAlign="space-around stretch" class="box_container">
    <!--info-->
    <div fxLayout="row" fxLayoutAlign="center center" style="padding: 20px;">
      <p *ngIf="isSettingAsFavorite" class="info_message">Primero debes iniciar sesión para poder agregar favoritos</p>
    </div>
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-around stretch">
      <!--login-->
      <div fxLayout="column" fxLayoutAlign="space-between stretch" fxFlex="45%">
        <h2>Si ya tienes cuenta, ingresa con tu correo</h2>
        <form [formGroup]="loginForm" fxLayout="column" fxLayoutAlign="space-evenly stretch">
          <mat-form-field appearance="outline">
            <mat-label>correo</mat-label>
            <input matInput formControlName="emailCtrl" name="description">
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>contraseña</mat-label>
            <input matInput type="password" formControlName="passwordCtrl" name="description">
          </mat-form-field>

          <div fxLayout="row" fxLayoutAlign="space-around stretch">
            <button mat-raised-button color="primary" (click)="loginWithUserPassword()">Ingresa</button>

          </div>
        </form>

        <div fxLayout="row" fxLayoutAlign="space-around center" class="third-party-login-container">
          <button mat-raised-button (click)="logInWithGoogle()" fxFlex="44%">
            <img [src]="googleLogoURL" width="30"> Ingresa
          </button>

          <button mat-raised-button (click)="logInWithFacebook()" fxFlex="44%" class="facebook-btn"> <img
              [src]="facebookLogoURL" width="30">
            Ingresa</button>

        </div>

      </div>

      <mat-divider fxShow fxHide.lt-md vertical style="height:300px;"></mat-divider>
      <!-- registro-->
      <div fxLayout="column" fxLayoutAlign="center stretch" fxFlex="45%">
        <h2>Si no tienes cuenta, regístrate gratis</h2>
        <form [formGroup]="registerForm" fxLayout="column" fxLayoutAlign="space-evenly stretch">
          <mat-form-field appearance="outline">
            <mat-label>correo</mat-label>
            <input matInput formControlName="emailCtrl">
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>contraseña</mat-label>
            <input matInput type="password" formControlName="passwordCtrl">
          </mat-form-field>


          <mat-form-field appearance="outline">
            <mat-label>confirma contraseña</mat-label>
            <input matInput type="password" formControlName="passwordConfirmCtrl">
          </mat-form-field>

          <div fxLayout="row" fxLayoutAlign="space-around stretch">
            <button mat-raised-button color="primary" (click)="signUpWithUserPassword()">Regístrate</button>

          </div>
        </form>
        <div fxLayout="row" fxLayoutAlign="space-around center" class="third-party-login-container">
          <button mat-raised-button (click)="logInWithGoogle()" fxFlex="44%">
            <img [src]="googleLogoURL" width="30"> Ingresa
          </button>

          <button mat-raised-button (click)="logInWithFacebook()" fxFlex="44%" class="facebook-btn"> <img
              [src]="facebookLogoURL" width="30">
            Ingresa</button>

        </div>
      </div>
    </div>

    <!--errors-->
    <div fxLayout="row" fxLayoutAlign="center center" style="padding: 20px;">
      <p class="error_message">{{errorMessage}}</p>

    </div>

  </div>


</div>