import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-age-field',
  templateUrl: './age-field.component.html',
  styleUrls: ['./age-field.component.scss']
})
export class AgeFieldComponent implements OnInit {

  @Input() formControl: FormControl = new FormControl();

  public ages = [
    { value: 0, label: '0 a 3 meses' },
    { value: 1, label: '3 a 12 meses' },
    { value: 2, label: '1 a 3 años' },
    { value: 3, label: 'más de 3 años' }];
  constructor() { }

  ngOnInit(): void {
  }

}
