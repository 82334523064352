import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Litter } from 'src/app/models/post';
import { MatChipInputEvent } from '@angular/material/chips/chip-input';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete/autocomplete';
import { Observable } from 'rxjs';
import { UtilsService } from 'src/app/services/utils.service';
import { map, startWith } from 'rxjs/operators';
import { DatabaseServiceService } from 'src/app/services/database-service.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-edit-litter',
  templateUrl: './edit-litter.component.html',
  styleUrls: ['./edit-litter.component.scss']
})
export class EditLitterComponent implements OnInit {

  public separatorKeysCodes: number[] = [ENTER, COMMA];
  public myPost: Litter;
  @Input() post: Litter;
  @ViewChild('vaccineInput') vaccineInput: ElementRef<HTMLInputElement> | undefined;
  public loading = false;

  breedCtrl = new FormControl('', [Validators.required]);

  petInfoForm = new FormGroup({
    birthCtrl: new FormControl('', [Validators.required]),
    descriptionCtrl: new FormControl('', [Validators.required]),
    vaccineCtrl: new FormControl(),
    breedCtrl: this.breedCtrl,
  });

  public hoveringCoverImage = false;
  public selectedVaccines: string[] = [];
  filteredVaccines: Observable<string[]>;
  commonVaccines: string[];


  public numberOfChildren;
  public children: FormGroup[] = [];
  public coverFoto: File;
  public motherFoto: File;
  public fatherFoto: File;
  public petImagesFiles = {};
  public petImages: string[] = [''];


  public message = '';
  public progressValue = 0;

  public isValidForm = false;
  constructor(private utils: UtilsService, private db: DatabaseServiceService, private router: Router) {
    this.utils.getDogVaccines().then(vac => {
      this.commonVaccines = vac;
      this.filteredVaccines = this.petInfoForm.controls.vaccineCtrl.valueChanges.pipe(
        startWith(null),
        map((vacc: string | null) => (vacc ? this._filter(vacc) : this.commonVaccines.slice())),
      );
    });


  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    if (value) {
      this.selectedVaccines.push(value);
    }

    // Clear the input value
    if (event.input) {
      event.input.value = '';
    }

    this.petInfoForm.controls.vaccineCtrl.setValue(null);
  }
  remove(vaccine: string): void {
    const index = this.selectedVaccines.indexOf(vaccine);

    if (index >= 0) {
      this.selectedVaccines.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.selectedVaccines.push(event.option.viewValue);
    this.vaccineInput!.nativeElement.value = '';
    this.petInfoForm.controls.vaccineCtrl.setValue(null);
  }

  public addChild(): void {
    this.numberOfChildren++;
    this.children.push(new FormGroup({
      sexCtrl: new FormControl('', [Validators.required]),
      priceCtrl: new FormControl('', [Validators.required]),
      imageCtrl: new FormControl('', [Validators.required]),
    }));
    this.children[this.children.length - 1].valueChanges.subscribe(x => {
      this.validate();
    });
    this.petImagesFiles[this.numberOfChildren - 1] = undefined;
    this.petImages.push('');

    this.children[this.children.length - 1].controls.sexCtrl.markAsUntouched();
    this.validate();

  }
  public removeChild(i: number): void {
    this.numberOfChildren--;
    this.children.splice(i, 1);
    this.validate();
  }

  public onSelectSingleFile(event, i): void {
    if (event.target.files && event.target.files.length) {

      const files = event.target.files;
      // tslint:disable-next-line: no-non-null-assertion
      this.petImagesFiles[i] = files.item(files.length - 1)!;
      console.log(this.petImagesFiles[i]);
      this.updatePicture(i);
    }
    this.validate();
  }

  public editCoverPhoto(event): void {
    if (event.target.files && event.target.files.length) {
      const files = event.target.files;
      // tslint:disable-next-line: no-non-null-assertion
      this.coverFoto = files.item(files.length - 1)!;
      const reader = new FileReader();
      reader.readAsDataURL(this.coverFoto);
      reader.onload = () => {
        this.myPost.picture = reader.result as string;
      };
    }
    this.validate();
  }

  public updatePicture(index: number): void {
    const reader = new FileReader();
    reader.readAsDataURL(this.petImagesFiles[index] as File);
    reader.onload = () => {
      this.petImages[index] = reader.result as string;
    };
  }

  private async uploadImagesMultiple(postID, progressStep): Promise<void> {
    this.message = 'Subiendo foto de portada';

    // upload images to storage and get download links
    console.log(this.coverFoto);
    console.log(this.myPost.picture);
    this.coverFoto !== undefined ? this.myPost.picture = await this.db.uploadImage(postID, this.coverFoto, 'cover') : console.log('cover foto did not change');

    if (this.motherFoto) {
      this.message = 'subiendo foto de la madre';
      this.myPost.mother!.image = await this.db.uploadImage(postID, this.motherFoto, 'mother');
      this.progressValue += progressStep;
    }
    if (this.fatherFoto) {
      this.message = 'subiendo foto del padre';
      this.myPost.father!.image = await this.db.uploadImage(postID, this.fatherFoto, 'father');
      this.progressValue += progressStep;
    }
    console.log(this.petImagesFiles);

    // only upload images that have changed
    // tslint:disable-next-line: forin
    for (const i in this.petImagesFiles) {
      this.message = 'Subiendo foto #' + (+i + 1);
      const url = await this.db.uploadImage(postID, this.petImagesFiles[i]!, i.toString());
      this.progressValue += progressStep;
      (this.myPost as Litter).pets[i].pictures![0] = url;
    }
  }

  private validate(): void {
    console.log('validating');
    console.log(this.petInfoForm);
    console.log(this.children);
    this.isValidForm = this.petInfoForm.valid && this.children.every(child => child.valid);
    this.myPost.description = this.petInfoForm.controls.descriptionCtrl.value;
    this.myPost.pets = [];
    this.myPost.animal = this.post.animal;
    this.myPost.breed = this.petInfoForm.controls.breedCtrl.value;
    this.children.forEach((child, i) => {
      this.myPost.pets.push(
        {
          sex: child.controls.sexCtrl.value, certificates: [],
          vaccines: this.selectedVaccines,
          breed: this.petInfoForm.controls.breedCtrl.value,
          birthDate: this.petInfoForm.controls.birthCtrl.value,
          price: child.controls.priceCtrl.value,
          pictures: [this.petImages[i]]

        }
      );
    });

  }
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.commonVaccines.filter(vaccine => vaccine.toLowerCase().includes(filterValue));
  }

  ngOnInit(): void {
    console.log(this.post);
    console.log(this.petInfoForm);
    this.petInfoForm.controls.birthCtrl.setValue(this.post.pets[0].birthDate);
    this.petInfoForm.controls.descriptionCtrl.setValue(this.post.description);
    this.petInfoForm.controls.vaccineCtrl.setValue(this.post.pets[0].vaccines);
    this.petInfoForm.controls.breedCtrl.setValue(this.post.pets[0].breed);

    this.selectedVaccines = this.post.pets[0].vaccines;

    this.numberOfChildren = this.post.pets.length;
    this.petImages = [];
    console.log('PETS====================', this.post.pets);
    this.post.pets.forEach((pet, i) => {
      console.log('creating', i, 'pet');
      this.children.push(new FormGroup({
        sexCtrl: new FormControl('', [Validators.required]),
        priceCtrl: new FormControl('', [Validators.required]),
        imageCtrl: new FormControl('', [Validators.required]),
      }));
      this.children[i].controls.sexCtrl.setValue(pet.sex);
      this.children[i].controls.priceCtrl.setValue(pet.price);
      this.children[i].controls.imageCtrl.setValue(pet.pictures![0]);

      this.petImages.push(pet.pictures![0]);
      console.log('finished creating', i, ' pet');
    });
    // subscribe for children changes
    this.children.forEach(child => {
      child.valueChanges.subscribe(x => {
        this.validate();
      });

    });

    // subscribe for petInfoForm changes
    this.petInfoForm.valueChanges.subscribe(x => {
      this.validate();
    });
    this.myPost = Object.assign({}, this.post);

    console.log('pet images', this.petImages);
  }

  private getProgressStep(): number {
    return 100 / (this.numberOfChildren + 2);
  }

  public async submitInfo(): Promise<void> {

    const progressStep = this.getProgressStep();
    this.loading = true;
    const postID = this.myPost.id;
    await this.uploadImagesMultiple(postID, progressStep);
    this.message = 'Creando publicación';

    console.log(this.myPost);
    await this.db.createPostLitter(this.myPost as Litter, postID);
    this.loading = false;
    this.progressValue = 0;
    // redirect to payment
    this.router.navigate(['/post_info', postID]);
  }


  public async savePost(): Promise<void> {
    if (this.isValidForm) {
      await this.submitInfo();
    }

  }

}
