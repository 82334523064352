import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import * as firebase from 'firebase/app';
import { User } from '../models/user';
import { DatabaseServiceService } from './database-service.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user$: Observable<any>;

  constructor(
    private afAuth: AngularFireAuth,
    private db: DatabaseServiceService,
    private afs: AngularFirestore,
    private router: Router
  ) {
    this.user$ = this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          return this.afs.doc<User>(`users/${user.uid}`).valueChanges();
        }
        return of(null);
      })
    );
  }

  public async googleSignIn(): Promise<void> {
    const credential = await this.afAuth.signInWithPopup(new firebase.default.auth.GoogleAuthProvider());
    const userInDb = await this.db.getUserFromID(credential.user?.uid!);
    // no need to update user if it's already in the db
    if (userInDb) {
      console.log('user already in db');
      return;

    }
    const user: User = { email: credential.user!.email!, uid: credential.user?.uid!, favorites: [], posts: [] };
    return this.db.updateUserData(user);
  }

  public async facebookSignIn(): Promise<void> {
    const credential = await this.afAuth.signInWithPopup(new firebase.default.auth.FacebookAuthProvider());
    const userInDb = await this.db.getUserFromID(credential.user?.uid!);
    // no need to update user if it's already in the db
    if (userInDb) {
      console.log('user already in db');
      return;

    }
    const user: User = { email: credential.user!.email!, uid: credential.user?.uid!, favorites: [], posts: [] };
    return this.db.updateUserData(user);
  }

  async signOut(): Promise<boolean> {
    await this.afAuth.signOut();
    return this.router.navigate(['/']);
  }

  // TODO: handle errors
  public async loginWithUserPassword(email, password): Promise<void> {
    const credential = await this.afAuth.signInWithEmailAndPassword(email, password);
    const user: User = { email: credential.user!.email!, uid: credential.user?.uid!, favorites: [], posts: [] };
    return this.db.updateUserData(user);

  }

  public async signUpWithUserPassword(email, password): Promise<any> {

    try {
      const credential = await this.afAuth.createUserWithEmailAndPassword(email, password);
      console.log('credential', credential);
      const user: User = { email: credential.user!.email!, uid: credential.user?.uid!, favorites: [], posts: [] };
      this.db.updateUserData(user);
      return true;
    } catch (error) {
      console.log(error);
      return error;
    }


  }

}
