<!--single pet-->
<mat-card *ngIf="post.litter === false" class="post-card" (click)="goToPost()">

    <div class="image-container post-cover-photo" fxLayoutAlign="center center">
        <img mat-card-image [src]="post.picture" class="card-image">
        <div color="accent">
            <button *ngIf="isFavorite" class="yellow-icon" mat-icon-button
                (click)="setAsNotFavorite(); $event.stopPropagation()">
                <mat-icon>star</mat-icon>
            </button>

            <button *ngIf="!isFavorite" class="black-icon" mat-icon-button
                (click)="setAsFavourite(); $event.stopPropagation()">
                <mat-icon>star</mat-icon>
            </button>
        </div>
    </div>
    <mat-card-content class="padding">
        <mat-card-header class="padding">
            <mat-card-title>{{post.pet.breed}}</mat-card-title>
            <mat-card-subtitle>{{post.city}}</mat-card-subtitle>
            <mat-card-subtitle *ngIf="post.adopt !== true"> {{post.price | currency:'$':'symbol':'4.0'}}
            </mat-card-subtitle>
            <mat-card-subtitle *ngIf="post.adopt === true"> En adopción</mat-card-subtitle>

        </mat-card-header>
    </mat-card-content>

</mat-card>

<!--litter -->
<mat-card *ngIf="post.litter === true" class="post-card" (click)="goToPost()">

    <div class="image-container post-cover-photo" fxLayoutAlign="center center">
        <img mat-card-image [src]="post.picture" class="card-image">
        <div color="accent">
            <button *ngIf="isFavorite" class="yellow-icon" mat-icon-button
                (click)="setAsNotFavorite(); $event.stopPropagation()">
                <mat-icon>star</mat-icon>
            </button>

            <button *ngIf="!isFavorite" class="black-icon" mat-icon-button
                (click)="setAsFavourite(); $event.stopPropagation()">
                <mat-icon>star</mat-icon>
            </button>
        </div>
    </div>
    <mat-card-content class="padding">
        <mat-card-header class="padding">
            <mat-card-title>Camada de {{post.breed}}</mat-card-title>
            <mat-card-subtitle>{{post.city}}</mat-card-subtitle>
            <mat-card-subtitle *ngIf="post.adopt === true"> En adopción</mat-card-subtitle>

        </mat-card-header>
    </mat-card-content>
</mat-card>