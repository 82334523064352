<form fxLayout="column" fxLayoutAlign="space-around stretch" *ngIf="user" [formGroup]="userInfoForm">
    <mat-form-field appearance="outline">
        <mat-label>Nombre</mat-label>
        <input matInput formControlName="nameCtrl">
        <mat-icon matSuffix>person</mat-icon>
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label>Celular</mat-label>
        <input matInput formControlName="phoneCtrl">
        <mat-icon matSuffix>phone</mat-icon>
    </mat-form-field>

    <!--
    <mat-form-field appearance="outline">
        <mat-label>Ciudad</mat-label>
        <input matInput formControlName="cityCtrl">
        <mat-icon matSuffix>location_city</mat-icon>
    </mat-form-field>
-->
    <app-city-field [formControl]="$any(userInfoForm.controls.cityCtrl)" [defaultCity]="user.city"
        (cityChange)="userInfoForm.controls.cityCtrl.setValue( $event)">
    </app-city-field>
    <div *ngIf="userInfoForm.get('cityCtrl')!.errors && 
            (userInfoForm.get('cityCtrl')!.dirty || userInfoForm.get('cityCtrl')!.touched)">
        <small class="text-danger" *ngIf="userInfoForm.get('cityCtrl')!.hasError('required')">
            Este campo es requerido
        </small>
        <small class="text-danger" *ngIf="userInfoForm.get('cityCtrl')!.hasError('invalid')!">
            Debe seleccionar una ciudad que se encuentre en el listado.
        </small>
    </div>


    <mat-form-field appearance="outline">
        <mat-label>Barrio / localidad</mat-label>
        <input matInput formControlName="neighbourhoodCtrl">
        <mat-icon matSuffix>location_on</mat-icon>

    </mat-form-field>

    <button mat-raised-button color="primary" (click)="submit()"
        [disabled]="!this.userInfoForm.valid">Continuar</button>

</form>