import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import { PostListComponent } from './components/post-list/post-list.component';

import { MatCardModule } from '@angular/material/card';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatGridListModule } from '@angular/material/grid-list';
import { CreatePostComponent } from './components/create-post/create-post.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { PostCardComponent } from './components/post-card/post-card.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { LoginComponent } from './components/login/login.component';
import { MatStepperModule } from '@angular/material/stepper';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDividerModule } from '@angular/material/divider';
import { AddPetComponent } from './components/add-pet/add-pet.component';
import { FileUploadTaskComponent } from './components/file-upload-task/file-upload-task.component';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { DropzoneDirective } from './directives/dropzone.directive';
import { AddLitterComponent } from './components/add-litter/add-litter.component';
import { BreedAutocompleteComponent } from './components/breed-autocomplete/breed-autocomplete.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { PostInfoComponent } from './components/post-info/post-info.component';
import { NgxGalleryModule } from 'ngx-gallery-9';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SellerInfoComponent } from './components/seller-info/seller-info.component';
import { PaymentComponent } from './components/payment/payment.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDialogModule } from '@angular/material/dialog';
import { PaymentDialog } from './components/payment/payment.component';
import { SearchComponent } from './components/search/search.component';
import { MatSliderModule } from '@angular/material/slider';
import { FavoritesComponent } from './components/favorites/favorites.component';
import { MyPostsComponent } from './components/my-posts/my-posts.component';
import { PublisherPostCardComponent } from './components/publisher-post-card/publisher-post-card.component';
import { GenerateCodesComponent } from './components/generate-codes/generate-codes.component';
import { SearchResultsComponent } from './components/search-results/search-results.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { EditProfileComponent } from './components/edit-profile/edit-profile.component';
import { MatMenuModule } from '@angular/material/menu';
import { EndPostDialogComponent } from './components/my-posts/my-posts.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { FooterComponent } from './components/footer/footer.component';
import { TermsComponent } from './components/terms/terms.component';
import { HelpComponent } from './components/help/help.component';
import { BreedsDialogComponent } from './components/breeds-dialog/breeds-dialog.component';
import { TitleBarComponent } from './components/title-bar/title-bar.component';
import { MatBadgeModule } from '@angular/material/badge';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { LandingComponent } from './components/landing/landing.component';
import { BreedFieldComponent } from './elements/breed-field/breed-field.component';
import { CatdogFieldComponent } from './elements/catdog-field/catdog-field.component';
import { AgeFieldComponent } from './elements/age-field/age-field.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { CityDialogComponent } from './components/city-dialog/city-dialog.component';
import { CityFieldComponent } from './elements/city-field/city-field.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PostInfoSingleComponent } from './components/post-info-single/post-info-single.component';
import { PostInfoLitterComponent } from './components/post-info-litter/post-info-litter.component';
import { EditPostComponent } from './components/edit-post/edit-post.component';
import { EditLitterComponent } from './components/edit-post/edit-litter/edit-litter.component';
import { EditSingleComponent } from './components/edit-post/edit-single/edit-single.component';
import { MatTabsModule } from '@angular/material/tabs';
import { HttpClientModule } from '@angular/common/http';
import { PostNotFoundComponent } from './components/post-not-found/post-not-found.component';


@NgModule({
  declarations: [
    AppComponent,
    PostListComponent,
    CreatePostComponent,
    NavBarComponent,
    PostCardComponent,
    LoginComponent,
    AddPetComponent,
    FileUploadTaskComponent,
    DropzoneDirective,
    AddLitterComponent,
    BreedAutocompleteComponent,
    PostInfoComponent,
    SellerInfoComponent,
    PaymentComponent,
    PaymentDialog,
    SearchComponent,
    FavoritesComponent,
    MyPostsComponent,
    PublisherPostCardComponent,
    GenerateCodesComponent,
    SearchResultsComponent,
    EditProfileComponent,
    EndPostDialogComponent,
    FooterComponent,
    TermsComponent,
    HelpComponent,
    BreedsDialogComponent,
    TitleBarComponent,
    LandingComponent,
    BreedFieldComponent,
    CatdogFieldComponent,
    AgeFieldComponent,
    CityDialogComponent,
    CityFieldComponent,
    PostInfoSingleComponent,
    PostInfoLitterComponent,
    EditPostComponent,
    EditLitterComponent,
    EditSingleComponent,
    PostNotFoundComponent,


  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    MatStepperModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatButtonModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAnalyticsModule,
    AngularFireModule,
    MatCardModule,
    FlexLayoutModule,
    MatGridListModule,
    MatToolbarModule,
    MatIconModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatButtonToggleModule,
    MatDividerModule,
    AngularFireStorageModule,
    MatProgressBarModule,
    NgxGalleryModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    MatDialogModule,
    MatSliderModule,
    MatCheckboxModule,
    MatRadioModule,
    MatMenuModule,
    MatSnackBarModule,
    MatBadgeModule,
    MatSidenavModule,
    MatTooltipModule,
    MatTabsModule
  ],
  providers: [MatDatepickerModule],
  bootstrap: [AppComponent]
})
export class AppModule { }
