import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { DatabaseServiceService } from 'src/app/services/database-service.service';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.css']
})
export class EditProfileComponent implements OnInit {


  public loading = false;

  public user: User;

  constructor(private auth: AuthService, private db: DatabaseServiceService) {
    this.auth.user$.subscribe(u => {
      this.user = Object.assign({}, u);
    });
  }

  public async update(): Promise<void>{
    this.loading = true;
    console.log('updating info');
    await this.db.updateUserData(this.user);
    this.loading = false;
  }

  ngOnInit(): void {
  }

}
