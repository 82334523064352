<div *ngIf="post && creator" fxLayoutAlign.gt-sm="space-around stretch" fxLayout="column" fxLayoutAlign="start stretch"
    class="container">

    <div class="section-container" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between stretch" fxLayout="column">

        <ngx-gallery class="ngx-gallery" (change)="chooseImage($event)" [options]="galleryOptions"
            [images]="galleryImages" fxFlex.gt-sm="45%">
        </ngx-gallery>

        <div class="box_container seller-info-container" fxFlex="45%" fxFlex.lt-md="90%" fxLayout="column"
            fxLayoutAlign="space-between stretch">

            <div>
                <div fxLayout="row" fxLayoutAlign="space-between start">
                    <div>
                        <h2 *ngIf="selectedChild === -1" id="title">Camada de {{post.breed}}</h2>

                        <div fxLayout="column" fxLayoutAlign="">
                            <h2 *ngIf="selectedChild != -1" id="title"> {{post.breed}} {{post.pets[selectedChild].sex
                                ===
                                'male' ? "macho" : "hembra"}}
                            </h2>
                            <p *ngIf="selectedChild != -1 && !post.adopt" id="price">{{post.pets[selectedChild].price |
                                currency:'$':'symbol':'4.0'}}</p>

                        </div>

                        <div *ngIf="post.adopt">
                            <br>
                            <h2 id="title">En adopción</h2>
                        </div>
                    </div>
                    <div color="accent" class="star-container">
                        <button *ngIf="isFavorite(post.id)" class="yellow-icon" mat-icon-button
                            (click)="setAsNotFavorite(); $event.stopPropagation()">
                            <mat-icon>star</mat-icon>
                        </button>

                        <button *ngIf="!isFavorite(post.id)" class="black-icon" mat-icon-button
                            (click)="setAsFavourite(); $event.stopPropagation()">
                            <mat-icon>star</mat-icon>
                        </button>
                    </div>
                </div>

                <!--<p id="price">{{post.price | currency:'$':'symbol':'4.0'}}</p>-->
                <p id="subtitle"> Datos del vendedor:</p>
                <p class="info">{{creator.name}}</p>
                <p class="info">{{creator.city}} - {{creator.neighbourhood}}</p>
                <p class="info">{{creator.phone}}</p>
            </div>
            <button mat-raised-button class="whatsapp-button" (click)="goToWhatsapp()">
                <img [src]="whatsappIconPath" alt="whatsapp icon" width="30">
                contactar vendedor
            </button>
        </div>
    </div>

    <!--
    <div fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start center" fxLayout="column">
        <div *ngIf="selectedChild !== -1" fxLayout="column" fxFlex="90%" [@panelInOut]>
            <p><b>Sexo: </b> {{post.pets[selectedChild].sex === 'male' ? "macho" : "hembra"}}</p>
            <p><b>Precio: </b>{{post.pets[selectedChild].price |currency:'$':'symbol':'4.0'}}</p>
        </div>
    </div>

    -->

    <mat-divider style="margin-bottom: 20px;"></mat-divider>

    <!--bottom-->
    <div fxLayout.lt-md="column" fxLayoutAlignlt-md="space-between center" fxLayout="row"
        fxLayoutAlign="space-between stretch">


        <!--PARENTS-->
        <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex="45%">
            <mat-card class="post-card" fxFlex="45%">
                <mat-card-header style="padding: 10px;">
                    <mat-card-title>Padre</mat-card-title>
                    <mat-card-subtitle>Raza: {{post.father?.breed != '' ? post.father?.breed : 'No especificada' }}
                    </mat-card-subtitle>
                    <mat-card-subtitle *ngIf="post.father?.name != ''">Nombre: {{post.father?.name}}
                    </mat-card-subtitle>

                </mat-card-header>
                <div class="image-container" fxLayoutAlign="center center">

                    <img class="card-image" [src]="post.father?.image != '' ? post.father?.image :  getAnimalIcon() "
                        alt="Photo of father">
                </div>
            </mat-card>

            <mat-card class="post-card" fxFlex="45%">
                <mat-card-header style="padding: 10px;">
                    <mat-card-title>Madre</mat-card-title>
                    <mat-card-subtitle>Raza: {{post.mother?.breed != '' ? post.mother?.breed : 'No especificada'}}
                    </mat-card-subtitle>
                    <mat-card-subtitle *ngIf="post.mother?.name != ''">Nombre: {{post.mother?.name}}
                    </mat-card-subtitle>

                </mat-card-header>
                <div class="image-container" fxLayoutAlign="center center">

                    <img class="card-image" [src]="post.mother?.image!= '' ? post.father?.image :  getAnimalIcon() "
                        alt="Photo of mother">
                </div>
            </mat-card>

        </div>
        <div fxFlex="45%">
            <div class="box_container" fxLayout="column">
                <h1>Datos</h1>

                <p><b>Fecha de nacimiento: </b>{{post.pets[0].birthDate | date}}</p>
                <p><b>Vacunas: </b>
                    <span *ngIf="post.pets[0].vaccines.length > 1">
                        <span *ngFor="let vac of post.pets[0].vaccines; last as last">
                            <span *ngIf="!last"> {{vac}},</span>
                            <span *ngIf="last"> {{vac}}</span>
                        </span>
                    </span>

                    <!--only one vaccine (no need for commas)-->
                    <span *ngIf="post.pets[0].vaccines.length  == 1">
                        <span *ngFor="let vac of post.pets[0].vaccines">{{vac}}</span>
                    </span>
                </p>

                <h2>Descripción</h2>
                <p>{{post.description}} </p>
            </div>
        </div>

    </div>
</div>