import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { Observable } from 'rxjs/internal/Observable';
import { map, startWith } from 'rxjs/operators';
import colombia from 'src/assets/json/Colombia.json';

export const _filter = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().includes(filterValue));
};
@Component({
  selector: 'app-city-field',
  templateUrl: './city-field.component.html',
  styleUrls: ['./city-field.component.scss']
})
export class CityFieldComponent implements OnInit {
  @Input() cityControl: FormControl = new FormControl();
  @Input() defaultCity: string | undefined;
  @Output() cityChange = new EventEmitter();

  options: any[] = [];
  citiesOptions: Observable<any[]>;
  constructor() { }


  ngOnInit(): void {
    if (this.defaultCity) {
      this.cityControl.setValue(this.defaultCity);
    }
    this.citiesOptions = this.cityControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filterGroup(value || '')),
    );
    this.options = colombia;
    console.log(this.options);

    this.cityControl.valueChanges.subscribe(x => {
      this.cityChange.emit(x);
    });
  }

  private _filterGroup(value: string): any[] {
    if (value) {
      return this.options
        .map(group => ({ departamento: group.departamento, ciudades: _filter(group.ciudades, value) }))
        .filter(group => group.ciudades.length > 0);
    }
    return this.options;
  }


}
