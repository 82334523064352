import { Component, OnInit } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {

  public loading = false;
  public terms: Promise<string>;
  public privacy: Promise<string>;


  constructor(private utilsService: UtilsService) { }

  ngOnInit(): void {
    this.terms = this.utilsService.getTermsAndConditions();
    this.privacy = this.utilsService.getPrivacyAgreement();

  }

}
