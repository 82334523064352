// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyBhc0R2o3FYONKFfuQbgSk3OHIbpyncZbg',
    authDomain: 'pet-sale-73f4e.firebaseapp.com',
    projectId: 'pet-sale-73f4e',
    storageBucket: 'pet-sale-73f4e.appspot.com',
    messagingSenderId: '184823416634',
    appId: '1:184823416634:web:aa3701ef811e80e2b77203',
    measurementId: 'G-MT670CJE76'
  }

};

