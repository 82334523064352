<div [ngClass]="{'blured': loading}">

    <!--LOADING SPINNER-->
    <div *ngIf="loading" class="loading-area" fxLayout="row" fxLayoutAlign="center center">
        <mat-spinner></mat-spinner>
    </div>

    <!--nav bar-->
    <app-nav-bar></app-nav-bar>

    <!--CONTENT-->
    <app-post-info-single *ngIf="post && post.litter == false" [post]="$any(post)" [creator]="creator" [user]="user"
        (isLoading)="this.loading=$event" (setAsFavouriteEmitter)="toggleFavorite($event)">
    </app-post-info-single>

    <app-post-info-litter *ngIf="post && post.litter == true" [post]="$any(post)" [creator]="creator" [user]="user"
        (isLoading)="this.loading=$event" (setAsFavouriteEmitter)="toggleFavorite($event)">
    </app-post-info-litter>

</div>