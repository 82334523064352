import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Post, Single } from 'src/app/models/post';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { DatabaseServiceService } from 'src/app/services/database-service.service';
import { NgxGalleryImage, NgxGalleryAnimation, NgxGalleryImageSize } from 'ngx-gallery-9';
import { AnalyticsService } from 'functions/services/analytics.service';


type NewType = NgxGalleryImage;

@Component({
  selector: 'app-post-info-single',
  templateUrl: './post-info-single.component.html',
  styleUrls: ['./post-info-single.component.scss']
})
export class PostInfoSingleComponent implements OnInit {


  public postID: string;
  public loading = false;
  public galleryOptions: any[];
  public galleryImages: NewType[];
  public showCreatorInfo = false;
  public whatsappIconPath = '/assets/icons/whatsapp.svg';
  public dogIconPath = '/assets/icons/Icono_perro_negro.png';
  public catIconPath = '/assets/icons/Icono_gato_negro.png';

  @Input() user: User;
  @Input() creator: User;
  @Input() post: Single;
  @Output() isLoading = new EventEmitter<boolean>();
  @Output() setAsFavouriteEmitter = new EventEmitter<boolean>();
  constructor(private route: ActivatedRoute,
    private db: DatabaseServiceService,
    private router: Router,
    private auth: AuthService,
    private analytics: AnalyticsService) {

    console.log('in post info single');
  }

  ngOnInit(): void {
    this.setupGalleryOptions();
    this.setupGalleryImages();
  }

  private setupGalleryImages(): void {
    this.galleryImages = [];

    this.galleryImages.push({
      small: this.post.picture,
      medium: this.post.picture,
      big: this.post.picture
    });
    this.post.pet.pictures?.forEach(p => {

      const temp = {
        small: p,
        medium: p,
        big: p
      };

      this.galleryImages.push(temp);
    });

  }


  private setupGalleryOptions(): void {

    this.galleryOptions = [
      {

        // thumbnailsColumns: this.post.pet.pictures!.length + 1,
        imageAnimation: NgxGalleryAnimation.Slide,
        previewCloseOnClick: true,
        previewCloseOnEsc: true,
        thumbnailsColumns: this.post.pet.pictures!.length + 1,
        imageSize: NgxGalleryImageSize.Contain,
        lazyLoading: true,
        previewArrows: true,
        imageArrows: true,
        thumbnailsArrows: true,
        thumbnailsSwipe: true,
        width: '100%',
        height: '300px',
        thumbnailSize: NgxGalleryImageSize.Contain
      },
      // max-width 800
      {
        breakpoint: 800,
        width: '100%',
        thumbnailsPercent: 20,
        thumbnailMargin: 0
      },
      // max-width 400
      {
        breakpoint: 200,
        preview: false,
        thumbnailsSwipe: true,
      }
    ];
  }

  /**
   * navigates to whatsapp API to open a chat to the creator phone number
   */
  public goToWhatsapp(): void {

    this.analytics.contactSeller(this.post.creator, this.post.id);

    const phoneNumber = '+57' + this.creator.phone;

    const whatsappMessageTemplate = `Hola! Estoy interesado en el anuncio del ${this.post.pet.breed}. Te puedo enviar un mensaje?`;
    const encodedWhatsappMessage = encodeURIComponent(whatsappMessageTemplate);
    console.log('whatsapp message: ' + encodedWhatsappMessage);

    const url = `https://wa.me/${phoneNumber}?text=` + encodedWhatsappMessage;
    window.open(url);
  }

  public isFavorite(postId: string): boolean {
    if (this.user === null) {
      return false;
    }
    const ans = this.user.favorites.indexOf(postId) !== -1;
    return ans;
  }

  public async setAsNotFavorite(): Promise<void> {
    this.setAsFavouriteEmitter.emit(false);

  }

  public async setAsFavourite(): Promise<void> {
    this.setAsFavouriteEmitter.emit(true);
  }



  public getAnimalIcon(): string {
    if (this.post.animal === 'dog') {
      return this.dogIconPath;
    }
    else {
      return this.catIconPath;
    }
  }
}
