import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips/chip-input';
import { faThList } from '@fortawesome/free-solid-svg-icons';
import { Observable } from 'rxjs/internal/Observable';
import { map, startWith } from 'rxjs/operators';
import { Litter, Pet, Single } from 'src/app/models/post';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-add-litter',
  templateUrl: './add-litter.component.html',
  styleUrls: ['./add-litter.component.scss']
})
export class AddLitterComponent implements OnInit, OnChanges {

  // for vaccie autocomplete
  public selectedVaccines: string[] = [];
  public separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredVaccines: Observable<string[]>;
  commonVaccines: string[];


  public numberOfChildren = 1;
  public children: FormGroup[] = [new FormGroup({
    sexCtrl: new FormControl('', [Validators.required]),
    priceCtrl: new FormControl('', []),
    imageCtrl: new FormControl('', [Validators.required]),

  })];

  @Input() animal = 'dog';

  public pets: any[] = [];
  public breed: string;
  public birthDate: Date;
  public isAdoption = true;

  breedCtrl = new FormControl('', [Validators.required]);


  petInfoForm = new FormGroup({
    nameCtrl: new FormControl('', []),
    birthCtrl: new FormControl('', [Validators.required]),
    descriptionCtrl: new FormControl('', [Validators.required]),
    vaccineCtrl: new FormControl(),
    breedCtrl: this.breedCtrl,
  });

  // for images
  public petImagesFiles: (File | undefined)[] = [undefined];
  public petImages: string[] = [''];


  @ViewChild('vaccineInput') vaccineInput: ElementRef<HTMLInputElement> | undefined;
  @ViewChild('myForm') form!: NgForm;
  @Input() myPost: Litter = {
    id: '',
    adopt: false,
    breed: '',
    animal: this.animal,
    creator: '',
    payed: false,
    city: '',
    active: false,
    creationDate: new Date(),
    expirationDate: new Date(),
    description: '',
    litter: true,
    views: 0,
    type: 'sell',
    pets: [],
    picture: 'https://images.dog.ceo/breeds/wolfhound-irish/n02090721_1688.jpg'

  };
  @Output() isValidForm = new EventEmitter<boolean>();
  @Output() myPostChange = new EventEmitter<Litter>();
  @Output() childrenImagesFilesChanges = new EventEmitter<(File | undefined)[]>();

  post = this.myPost;
  allBreeds = new Set<string>();

  constructor(private utils: UtilsService) {

    console.log(this.myPost);

    this.petInfoForm.valueChanges.subscribe(x => {
      this.updateObject(x);
    });

    this.utils.getDogVaccines().then(vac => {
      this.commonVaccines = vac;
      this.filteredVaccines = this.petInfoForm.controls.vaccineCtrl.valueChanges.pipe(
        startWith(null),
        map((vacc: string | null) => (vacc ? this._filter(vacc) : this.commonVaccines.slice())),
      );
    });

    this.petInfoForm.valueChanges.subscribe(x => {
      this.validate();
    });

    this.children.forEach(child => {
      child.valueChanges.subscribe(x => {
        this.validate();
      });
    });
  }


  private validate(): void {
    this.myPost.adopt = this.isAdoption;
    this.isValidForm.emit(this.petInfoForm.valid && this.children.every(child => child.valid));
    this.myPost.description = this.petInfoForm.controls.descriptionCtrl.value;
    this.myPost.pets = [];
    this.myPost.animal = this.animal;
    this.myPost.breed = this.petInfoForm.controls.breedCtrl.value;
    this.children.forEach((child, i) => {
      this.myPost.pets.push(
        {
          sex: child.controls.sexCtrl.value, certificates: [],
          vaccines: this.selectedVaccines,
          breed: this.petInfoForm.controls.breedCtrl.value,
          birthDate: this.petInfoForm.controls.birthCtrl.value,
          price: this.isAdoption ? 0 : child.controls.priceCtrl.value,
          pictures: [this.petImages[i]]

        }
      );
    });

    this.myPostChange.emit(this.myPost);
  }

  private updateObject(f: any): void {
    this.myPost.description = f.descriptionCtrl;
    this.birthDate = f.birthCtrl;
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    if (value) {
      this.selectedVaccines.push(value);
    }

    // Clear the input value
    if (event.input) {
      event.input.value = '';
    }

    this.petInfoForm.controls.vaccineCtrl.setValue(null);
  }
  remove(vaccine: string): void {
    const index = this.selectedVaccines.indexOf(vaccine);

    if (index >= 0) {
      this.selectedVaccines.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.selectedVaccines.push(event.option.viewValue);
    this.vaccineInput!.nativeElement.value = '';
    this.petInfoForm.controls.vaccineCtrl.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.commonVaccines.filter(vaccine => vaccine.toLowerCase().includes(filterValue));
  }

  public addChild(): void {
    this.numberOfChildren++;
    this.children.push(new FormGroup({
      sexCtrl: new FormControl('', [Validators.required]),
      priceCtrl: new FormControl('', [Validators.required]),
      imageCtrl: new FormControl('', [Validators.required]),
    }));
    this.children[this.children.length - 1].valueChanges.subscribe(x => {
      this.validate();
    });
    this.petImagesFiles.push(undefined);
    this.petImages.push('');

    this.children[this.children.length - 1].controls.sexCtrl.markAsUntouched();
    this.validate();

  }

  public removeChild(i: number): void {
    this.numberOfChildren--;
    this.children.splice(i, 1);
    this.validate();
  }


  // for images
  onSelectSingleFile(event, i): void {
    if (event.target.files && event.target.files.length) {

      const files = event.target.files;
      // tslint:disable-next-line: no-non-null-assertion
      this.petImagesFiles[i] = files.item(files.length - 1)!;
      console.log(this.petImagesFiles[i]);
      this.updatePicture(i);
      this.childrenImagesFilesChanges.emit(this.petImagesFiles);
    }
  }


  public adopt(): void {
    this.isAdoption = true;
    this.validate();
  }

  public sell(): void {
    this.isAdoption = false;
    this.validate();
  }

  updatePicture(index: number): void {

    const reader = new FileReader();

    reader.readAsDataURL(this.petImagesFiles[index] as File);

    reader.onload = () => {
      this.petImages[index] = reader.result as string;
    };
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (this.animal === 'dog') {
      const breeds = await this.utils.getDogBreeds();
      breeds.forEach(b => this.allBreeds.add(b));
    }
    else {
      const breeds = await this.utils.getCatBreeds();
      breeds.forEach(b => this.allBreeds.add(b));
    }
    this.petInfoForm.get('breedCtrl')?.markAsUntouched();
    this.petInfoForm.get('breedCtrl')?.valueChanges.subscribe(x => {
      if (!this.allBreeds.has(x)) {
        this.petInfoForm.get('breedCtrl')?.setErrors({ invalid: true });
        this.petInfoForm.get('breedCtrl')?.markAsTouched();
      }
    });
  }
  async ngOnInit(): Promise<void> {
  }

}
