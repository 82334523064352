<div [ngClass]="{'blured': loading}" fxLAyout="column">

    <app-nav-bar></app-nav-bar>
    <!--LOADING SPINNER-->
    <div *ngIf="loading" class="loading-area" fxLayout="row" fxLayoutAlign="center center">
        <mat-spinner></mat-spinner>
    </div>



    <div fxLayout="row" fxLayoutAlign="center center" class="container">
        <div class="box_container" id="terms_container">
            <h1>Términos y condiciones</h1>
            <p>{{terms | async}}</p>

            <h1>Política de privacidad</h1>
            <p>{{privacy | async}}</p>
        </div>
    </div>
</div>