<div [ngClass]="{'blured': loading}" fxLayout="column">


    <!--LOADING SPINNER-->
    <div *ngIf="loading" class="loading-area" fxLayout="row" fxLayoutAlign="center center">
        <mat-spinner></mat-spinner>
    </div>
    <app-nav-bar></app-nav-bar>


    <div class="container">

        <p>Se generarán X códigos con fecha de expiración: {{this.expirationDate | date}}</p>

        <mat-form-field class="example-form-field" appearance="fill">
            <mat-label>Cantidad de codigos</mat-label>
            <input matInput type="number" min="1" [(ngModel)]="numberOfCodes">
        </mat-form-field>
        <button mat-raised-button (click)="addCodes()">Generar</button>

        <mat-card *ngIf="codes.length > 0" id="codes-card">
            Cantidad de códigos generados: {{codes.length}}
        <p *ngFor="let code of codes">{{code}}</p>
    </mat-card>

    </div>

</div>