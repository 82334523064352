<div [ngClass]="{'blured': loading}">


    <!--LOADING SPINNER-->
    <div *ngIf="loading" class="loading-area" fxLayout="row" fxLayoutAlign="center center">
        <mat-spinner></mat-spinner>
    </div>
    <app-nav-bar></app-nav-bar>

    <div *ngIf="notFound">
        <h1>La publicación no existe</h1>
    </div>
    <div *ngIf="!notFound && this.post" fxLayout="column">
        <h1>Editar publicación</h1>

        <div style="padding: 20px;">
            <app-edit-litter *ngIf="post.litter === true" [post]="$any(post)"></app-edit-litter>
            <app-edit-single *ngIf="post.litter === false" [post]="$any(post)"></app-edit-single>
        </div>
    </div>

</div>