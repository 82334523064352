import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, max, startWith } from 'rxjs/operators';
import { COMMA, ENTER } from '@angular/cdk/keycodes';

import { DatabaseServiceService } from 'src/app/services/database-service.service';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatSliderChange } from '@angular/material/slider';
import { UtilsService } from 'src/app/services/utils.service';
import { Filters } from 'src/app/models/filters';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  // city
  city = '';

  // breed chip
  separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredBreeds: Observable<string[]>;

  breedCtrl = new FormControl();
  breeds: string[] = [];
  allBreeds: string[];
  @ViewChild('breedInput') breedInput: ElementRef<HTMLInputElement>;

  // sex
  sex: string;

  // price
  maxPrice = 10000000;
  priceStep = 1000;
  price = this.maxPrice;
  displayPrice = this.price;


  @Output() filters = new EventEmitter<Filters>();
  constructor(private utils: UtilsService) {

    this.utils.getDogBreeds().then(breeds => {

      this.allBreeds = breeds;
      this.filteredBreeds = this.breedCtrl.valueChanges.pipe(
        startWith(null),
        map((breed: string | null) => (breed ? this._filter(breed) : this.allBreeds.slice())),
      );
    });

  }

  ngOnInit(): void {

  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our fruit
    if (value) {
      this.breeds.push(value);
    }

    // Clear the input value
    // tslint:disable-next-line: no-non-null-assertion
    event.input.value = '';

    this.breedCtrl.setValue(null);
  }

  remove(breed: string): void {
    const index = this.breeds.indexOf(breed);

    if (index >= 0) {
      this.breeds.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.breeds.push(event.option.viewValue);
    this.breedInput.nativeElement.value = '';
    this.breedCtrl.setValue(null);
  }

  public queryPosts(): void {
    const valueToEmit: Filters = { sex: this.sex, breed: this.breeds, maxPrice: this.displayPrice, city: this.city };
    // console.log(valueToEmit);
    this.filters.emit(valueToEmit);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allBreeds.filter(option => option.toLowerCase().includes(filterValue));
  }


  /**
   *  uses the slider to update the display value logarithmicaly
   *
   */
  updateBudget(event: MatSliderChange): void {

    // console.log(event.value);
    this.displayPrice = this.expo(event.value!);
  }

  private expo(num: number): number {
    const minv = 0;
    const maxv = Math.log(this.maxPrice);

    // calculate adjustment factor
    const scale = (maxv - minv) / (this.maxPrice - 0);

    return Math.round(Math.exp(minv + scale * (num!)));
  }
}
