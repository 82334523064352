<form class="example-form" style="width: 100%;">
  <mat-form-field appearance="outline" color="primary" style="width: 100%;"
    [matTooltip]="'Elige un tipo de mascota primero.'" [matTooltipDisabled]="animal == 'cat' || animal == 'dog'">
    <mat-label>Raza</mat-label>
    <input type="text" placeholder="Pick one" aria-label="Number" matInput [formControl]="breedFormControl"
      [matAutocomplete]="auto">
    <mat-icon matSuffix>pets</mat-icon>
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
        {{option}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>