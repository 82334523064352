<div *ngIf="loading" class="loading-area" fxLayout="column" fxLayoutAlign="center center">
    <mat-spinner></mat-spinner>
    <mat-card>
        <p><b>{{message}}</b></p>
    </mat-card>
</div>
<div fxLayout="column" fxLayoutAlign="center center" [ngClass]="{'blured': loading}">

    <form fxLayout="column" [formGroup]="petInfoForm" style="width: 500px;">
        <div class="cover-image-container" (mouseover)="hoveringCoverImage=true" (mouseout)="hoveringCoverImage=false"
            (click)="coverFileInput.click()">
            <img [src]="myPost.picture" class="edit-cover-image">
            <mat-icon *ngIf="hoveringCoverImage" class="centered edit-icon">edit</mat-icon>
            <input #coverFileInput type="file" accept=".jpg,.jpeg,.png,.webp" (change)="editCoverPhoto($event)"
                style="display:none;" />
        </div>
        <app-breed-autocomplete [animal]="myPost.animal" [initial]="myPost.pet.breed" [formControl]="breedCtrl">
        </app-breed-autocomplete>

        <mat-form-field appearance="outline">
            <mat-label>Fecha de nacimiento</mat-label>
            <input matInput [matDatepicker]="picker" formControlName="birthCtrl" name="birthDate" ngDefaultControl>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

        <!--Vacunas-->
        <mat-form-field class="example-chip-list" appearance="outline">
            <mat-label>Vacunas</mat-label>
            <mat-chip-list #chipList>
                <mat-chip *ngFor="let vaccine of selectedVaccines" (removed)="remove(vaccine)">
                    {{vaccine}}
                    <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input placeholder="Otra vacuna..." #vaccineInput formControlName="vaccineCtrl" [matAutocomplete]="auto"
                    [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    (matChipInputTokenEnd)="add($event)">
            </mat-chip-list>
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                <mat-option *ngFor="let vaccine of filteredVaccines | async" [value]="vaccine">
                    {{vaccine}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <mat-form-field appearance="outline" style="height: 100%;">
            <mat-label>Descripción</mat-label>
            <textarea matInput formControlName="descriptionCtrl" name="description" style="height: 100%;"
                rows="11"></textarea>
        </mat-form-field>


        <mat-form-field appearance="outline">
            <mat-label>Precio</mat-label>
            <span matSuffix>COP &nbsp;</span>
            <input matInput type="number" formControlName="priceCtrl" name="price">
        </mat-form-field>

        <!------------------------>
        <div fxLayout="row" class="edit-gallery-container">
            <div *ngFor="let image of myPost.pet.pictures; let i = index"
                class="cover-image-container gallery-image-container" (mouseover)="hoveringGalleryImage[i]=true"
                (mouseout)="hoveringGalleryImage[i]=false" (click)="galleryFileInput.click()">
                <img [src]="myPost!.pet!.pictures![i]" class="edit-gallery-image">
                <mat-icon *ngIf="hoveringGalleryImage[i]" class="centered edit-icon">edit</mat-icon>
                <input #galleryFileInput type="file" accept=".jpg,.jpeg,.png,.webp"
                    (change)="editGalleryFoto($event, i)" style="display:none;" />
            </div>
        </div>
        <button mat-raised-button color="primary" type="submit" class="primary-btn" style="width: 200px;"
            [disabled]="!isValidForm" (click)="savePost()">
            Guardar</button>
    </form>

</div>