import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { Pay } from '../models/pay';

const firebaseAPI = 'https://us-central1-pet-sale-73f4e.cloudfunctions.net';
// const firebaseAPI = 'http://localhost:5000/pet-sale-73f4e/us-central1';
@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  public isTest = true;
  private testAccount = '512321';
  public testEndpoint = 'https://sandbox.checkout.payulatam.com/ppp-web-gateway-payu/';
  private testAPIKey = '4Vj8eK4rloUd272L48hsrarnUA';
  private testMerchant = '508029';

  ////////
  public payUEndpoint = 'https://checkout.payulatam.com/ppp-web-gateway-payu/';
  public merchantId = '983068';
  public accountID = '991124';
  private apiKey = '5wIW834cZ5zpt1DkI3Gm75mB19';

  constructor(private firestore: AngularFirestore, private http: HttpClient) {
    if (this.isTest) {
      this.accountID = this.testAccount;
      this.payUEndpoint = this.testEndpoint;
      this.apiKey = this.testAPIKey;
      this.merchantId = this.testMerchant;
    }
  }

  /**
   * Call API to check if the promo code is availible and changes the status of the post if it is
   * @param promoCode promo code
   * @param postID post id
   * @param ammount ammount to pay
   * @returns http response
   */
  public usePromoCode(promoCode: string, postID: string, ammount: number): Observable<any> {

    const ans = this.http.post(`${firebaseAPI}/pay_with_promo_code`, { promoCode, postID, ammount });
    return ans;

  }


  public sendPaymentConfirmation(payUInfo: any): Observable<any> {
    return this.http.post(`${firebaseAPI}/pay_u_confirmation`, payUInfo);
  }

  public async getPaymentStatus(postID: string): Promise<Pay | undefined> {
    console.log('getting payment status' + postID);
    const response = await this.firestore.collection('payments').doc(postID).get().toPromise();
    console.log(response.data());
    console.log('response:', response);
    return response.data() as unknown as (Pay | undefined);
  }

  public async getPayUInfo(ammount, reference: string, postID: string, buyerEmail: string): Promise<any> {
    return this.http.get(`${firebaseAPI}/pay_u_info`, {
      params: {
        ammount: ammount.toString(),
        reference,
        postID,
        buyerEmail,
        isTest: true.toString(),
      }
    }).toPromise();
  }

  public async getPayUConfirmation(postID: string): Promise<any> {
    return await this.http.get(`${firebaseAPI}/pay_u_confirmation`, {
      params: {
        reference: postID,
      }
    });
  }
}
