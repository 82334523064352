import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { AnalyticsService } from 'functions/services/analytics.service';
import { DatabaseServiceService } from 'src/app/services/database-service.service';
import { User } from 'src/app/models/user';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  private navigation;
  public loading = false;
  public errorMessage = '';
  public googleLogoURL =
    'https://raw.githubusercontent.com/fireflysemantics/logo/master/Google.svg';
  public facebookLogoURL = 'https://freepngimg.com/download/facebook/62487-bluetie-icons-computer-facebook-login-icon-email.png';
  public isSettingAsFavorite = false;
  private userSubscription: Subscription;


  loginForm = new FormGroup({
    emailCtrl: new FormControl('', [Validators.required]),
    passwordCtrl: new FormControl('', [Validators.required]),
  });


  registerForm = new FormGroup({
    emailCtrl: new FormControl('', [Validators.required]),
    passwordCtrl: new FormControl('', [Validators.required]),
    passwordConfirmCtrl: new FormControl('', [Validators.required]),
  });

  constructor(
    private authService: AuthService,
    private router: Router,
    private location: Location,
    private analytics: AnalyticsService,
    private db: DatabaseServiceService) {
    this.navigation = this.router.getCurrentNavigation();
    this.userSubscription = this.authService.user$.subscribe(u => {
      if (u !== null) {
        console.log('user', u);
        this.redirect(u);
        this.userSubscription.unsubscribe();
        console.log('unsubscribed');
      }
    });
  }

  private async redirect(user?: User): Promise<void> {
    if (this.navigation.extras.state) {
      const state = this.navigation!.extras.state as {
        filters: any
      };
      this.isSettingAsFavorite = this.navigation!.extras.state.action === 'setAsFavorite';
      const postId = this.navigation!.extras.state.postId;

      if (state !== undefined && state.filters !== undefined) {
        // set as favourite
        if (this.isSettingAsFavorite && user) {
          await this.db.setAsFavourite(postId, user!.uid);
        }
        await this.router.navigate(['/search'], { queryParams: state.filters });
      }
    }
    else {
      await this.router.navigate(['landing']);
    }
  }

  public async logInWithGoogle(): Promise<void> {
    await this.authService.googleSignIn();
    this.analytics.login('Google');
  }

  public async logInWithFacebook(): Promise<void> {
    await this.authService.facebookSignIn();
    this.analytics.login('Facebook');
  }

  public async loginWithUserPassword(): Promise<void> {
    const email = this.loginForm.controls.emailCtrl.value;
    const password = this.loginForm.controls.passwordCtrl.value;

    await this.authService.loginWithUserPassword(email, password);
    this.analytics.login('Email');

  }

  public async signUpWithUserPassword(): Promise<void> {
    const email = this.registerForm.controls.emailCtrl.value;
    const password = this.registerForm.controls.passwordCtrl.value;
    const confirmEmail = this.registerForm.controls.passwordConfirmCtrl.value;

    if (password !== confirmEmail) {
      this.errorMessage = 'Las contraseñas no coinciden';
      return;
    }

    const ans = await this.authService.signUpWithUserPassword(email, password);
    console.log('ans', ans);
    // user registered successfully
    if (ans === true) {
      this.analytics.register('email');
      this.analytics.login('email');
      await this.redirect();

    }
    else if (ans instanceof Error) {
      console.log(ans.message);
      if (ans.message === 'The email address is already in use by another account.') {
        this.loginForm.controls.emailCtrl.setErrors({ alreadyInUse: true });
        this.errorMessage = 'Ese correo ya se encuentra registrado.';
      }
      else {
        this.errorMessage = ans.message;
      }
    }
    else {
      console.log('unknown error');
      this.errorMessage = 'Error desconocido.';
    }
  }

  public async signOut(): Promise<void> {
    this.authService.signOut();
  }
  ngOnInit(): void {
    this.isSettingAsFavorite = this.navigation!.extras.state.action === 'setAsFavorite';
  }

}
