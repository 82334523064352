<!--single pet-->
<mat-card *ngIf="post.litter === false" class="publisher-post-card">
    <!-- delete button-->
    <div *ngIf="post.active && post.payed" class="custom-badge">
        <button mat-fab color="warn" class="delete-button" (click)="finishPost()">
            <mat-icon>delete</mat-icon>
        </button>
    </div>
    <div class="image-container post-cover-photo" fxLayoutAlign="center center">
        <img mat-card-image [src]="post.picture" class="card-image">
    </div>
    <mat-card-content class="padding">
        <h2>{{post.pet.breed}}</h2>
        <span>{{post.price | currency:'$':'symbol':'4.0'}}</span>
        <ul>
            <li>
                Fecha de publicacion: {{post.creationDate| date}}
            </li>
            <li>Fecha de vencimiento: {{post.expirationDate | date }}</li>
        </ul>

    </mat-card-content>
    <mat-card-actions class="padding" fxLayout="row" fxLayoutAlign="space-between center">
        <!-- <button *ngIf="post.active && post.payed" color="warn" mat-raised-button (click)="finishPost()">
            Terminar
        </button>-->
        <button *ngIf="!post.payed" color="primary" mat-raised-button (click)="payPost()">
            Pagar
        </button>

        <span *ngIf="post.payed && !post.active">
            Publicacion terminada
        </span>
        <button *ngIf="!post.payed || post.active" mat-raised-button (click)="editPost()">
            Editar
        </button>

        <div *ngIf="post.payed" fxLayout="row" fxLayoutAlign="center center">
            <span>{{post.views}}</span>
            <mat-icon>visibility</mat-icon>
        </div>


    </mat-card-actions>
</mat-card>



<!--litter pet-->
<mat-card *ngIf="post.litter === true" class="publisher-post-card">
    <!-- delete button-->
    <div *ngIf="post.active && post.payed" class="custom-badge">
        <button mat-fab color="warn" class="delete-button" (click)="finishPost()">
            <mat-icon>delete</mat-icon>
        </button>
    </div>
    <div class="image-container post-cover-photo" fxLayoutAlign="center center">
        <img mat-card-image [src]="post.picture" class="card-image">
    </div>
    <mat-card-content class="padding">
        <h2>Camada de {{post.breed}}</h2>
        <span>{{post.pets.length}} cachorros</span>
        <ul>
            <li>
                Fecha de publicacion: {{post.creationDate| date}}
            </li>
            <li>Fecha de vencimiento: {{post.expirationDate | date }}</li>
        </ul>

    </mat-card-content>
    <mat-card-actions class="padding" fxLayout="row" fxLayoutAlign="space-between center">
        <!-- <button *ngIf="post.active && post.payed" color="warn" mat-raised-button (click)="finishPost()">
            Terminar
        </button>-->
        <button *ngIf="!post.payed" color="primary" mat-raised-button (click)="payPost()">
            Pagar
        </button>

        <span *ngIf="post.payed && !post.active">
            Publicacion terminada
        </span>
        <button *ngIf="!post.payed || post.active" mat-raised-button (click)="editPost()">
            Editar
        </button>

        <div *ngIf="post.payed" fxLayout="row" fxLayoutAlign="center center">
            <span>{{post.views}}</span>
            <mat-icon>visibility</mat-icon>
        </div>
    </mat-card-actions>
</mat-card>