import { INFERRED_TYPE } from '@angular/compiler/src/output/output_ast';
import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireStorage, AngularFireStorageModule } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { Litter, Pet, Post, Single } from '../models/post';
import { User } from '../models/user';
import firebase, * as firestoreApp from 'firebase/app';
import { Capability } from 'protractor';
import { UtilsService } from './utils.service';
import { Filters } from '../models/filters';

@Injectable({
  providedIn: 'root'
})
export class DatabaseServiceService {


  private INF = 999999999;

  private dogBreedsArray: string[];
  private catBreedsArray: string[];

  constructor(private firestore: AngularFirestore, private storage: AngularFireStorage, private utils: UtilsService) {
  }



  /**
   * queries a single post given the ID
   * @param postID post id
   * @returns Post from firebase
   */
  public async getPostFromID(postID: string): Promise<Post | undefined> {
    console.log('getting post' + postID);
    const response = await this.firestore.collection('post').doc(postID).get().toPromise();
    console.log(response.data());
    if (response.data() === undefined) {
      console.log('returning undefined');
      return undefined;
    }
    const post = this.transformToPost(response.data());
    console.log(post);
    return post;
  }

  /**
   * takes response from firebase and transforms it into Post object
   * @param p object containing raw data from firebase
   * @returns Post object
   */
  private transformToPost(p: any): Post {
    let tempPost: Post;

    console.log(p);
    // Handle Litter
    if (p.litter === true) {
      const tempPets: Pet[] = [];

      ((p.pets) as Array<any>).forEach(incomingPet => {
        tempPets.push({
          birthDate: new Date(incomingPet.birthDate.seconds * 1000),
          sex: incomingPet.sex,
          breed: incomingPet.breed,
          certificates: incomingPet.certificates,
          vaccines: incomingPet.vaccines,
          price: incomingPet.price,
          sold: incomingPet.sold,
          pictures: incomingPet.pictures,
        });
      });

      tempPost = {
        animal: p.animal,
        id: p.id,
        city: p.city,
        creator: p.creator,
        active: p.active,
        description: p.description,
        creationDate: new Date(p.creationDate.seconds * 1000),
        expirationDate: new Date(p.expirationDate.seconds * 1000),
        litter: p.litter,
        payed: p.payed,
        type: p.type,
        pets: tempPets,
        views: p.views,
        picture: p.picture,
        father: p.father,
        mother: p.mother,
        breed: p.breed,
        adopt: p.adopt ?? false,
      } as Litter;

    }
    // handle single dog post
    else {
      tempPost = {
        animal: p.animal,
        adopt: p.adopt ?? false,
        id: p.id,
        creator: p.creator,
        city: p.city,
        active: p.active,
        description: p.description,
        payed: p.payed,
        litter: p.litter,
        price: p.price,
        creationDate: new Date(p.creationDate.seconds * 1000),
        expirationDate: new Date(p.expirationDate.seconds * 1000),
        type: p.type,
        pet: {
          birthDate: new Date(p.pet.birthDate.seconds * 1000),
          sex: p.pet.sex,
          breed: p.pet.breed,
          certificates: p.pet.certificates,
          vaccines: p.pet.vaccines,
          pictures: p.pet.pictures,

        },
        father: p.father,
        mother: p.mother,
        views: p.views,
        picture: p.picture
      } as Single;
    }
    return tempPost;
  }



  /**
   * uploads an image to firebase storage under a folder with the post ID
   * @param postId post id
   * @param file image
   * @param filename name of the file to be saved in firebase
   * @returns download URL of the image that was just uploaded
   */
  public async uploadImage(postId: string, file: File, filename: string): Promise<string> {

    console.log('uploading image');

    // createsa reference on storage using post id
    const path = `post_images/${postId}/${filename}`;
    const ref = this.storage.ref(path);
    const task = this.storage.upload(path, file);

    await task.percentageChanges().toPromise();

    const downloadURL = await ref.getDownloadURL().toPromise();

    console.log(downloadURL);

    // this.firestore.collection(`post`).doc(postId).set({picture: downloadURL}, {merge: true});
    return downloadURL as string;

  }

  /**
   * saves the post in firestore
   * @param post oobject representing the post
   */
  public async createPostSingle(post: Single, postID: string): Promise<void> {
    return this.firestore.collection('post').doc(postID).set(post);
  }


  /**
   * saves the post in firestore
   * @param post oobject representing the post
   */
  public async createPostLitter(post: Litter, postID: string): Promise<void> {
    const postToSave: any = { ...post };
    postToSave.sexes = post.pets.map(p => p.sex);
    postToSave.birthDate = postToSave.pets[0].birthDate;
    console.log('saving litter: ', postToSave);
    return this.firestore.collection('post').doc(postID).set(postToSave);
  }
  /**
   *
   * @returns Unique ID
   */
  public createID(): string {
    return this.firestore.createId();
  }


  public updateUserData(user: User): Promise<void> {
    const userRef: AngularFirestoreDocument<User> = this.firestore.doc(`users/${user.uid}`);

    return userRef.set(user, { merge: true });
  }



  public async getUserFromID(userID: string): Promise<User> {
    const response = await this.firestore.collection('users').doc(userID).get().toPromise();
    console.log(response);
    return response.data() as User;
  }

  public async isAvailible(promoCode: string): Promise<boolean> {
    console.log(promoCode);
    const snapshot = await this.firestore.collection('codes').doc(promoCode).get().toPromise();
    const ans = snapshot.data() as any;
    console.log('ans', ans);
    if (ans === undefined) {
      return false;
    }
    // check code expiration date
    const expiration = new Date(ans.expiration.seconds * 1000);
    const now = new Date();
    console.log(now);
    if (expiration < now) {
      console.log('expired promo code');
      return false;
    }
    return ans.available;

  }

  public async queryPosts(filters?: Filters): Promise<Post[]> {

    // this.dogBreedsArray = await this.utils.getDogBreeds();
    // this.catBreedsArray = await this.utils.getCatBreeds();
    // const allBreeds = [...this.catBreedsArray, ...this.dogBreedsArray];
    console.log(this.dogBreedsArray);

    // this brings all posts
    const defaultFilters = {
      breed: undefined,
      sex: undefined,
      maxPrice: this.INF,
      minPrice: 0,
      city: undefined,
      maxAgeMonths: this.INF,
      minAgeMonths: 0,
      adopt: undefined
    };
    if (filters === undefined) {
      filters = defaultFilters;
    }
    if (filters.animal === 'cat') {
      if (filters.breed === undefined) {
        filters = { sex: filters.sex, breed: this.catBreedsArray, maxPrice: filters.maxPrice, city: filters.city } as Filters;
      }
      if (filters.breed!.length === 0) {

        filters.breed = this.catBreedsArray;
      }
    } else if (filters.animal === 'dog') {
      if (filters.breed === undefined) {
        filters = { sex: filters.sex, breed: this.dogBreedsArray, maxPrice: filters.maxPrice, city: filters.city } as Filters;
      }
      if (filters.breed!.length === 0) {

        filters.breed = this.dogBreedsArray;
      }
    } else {
      if (filters.breed === undefined) {
        filters = { sex: filters.sex, breed: undefined, maxPrice: filters.maxPrice, city: filters.city } as Filters;
      }
      if (filters.breed!.length === 0) {
        filters.breed = undefined;
      }
    }

    if (filters.maxAgeMonths === undefined) {
      filters.maxAgeMonths = 1000;
    }
    if (filters.minAgeMonths === undefined) {
      filters.minAgeMonths = 0;
    }

    if (filters.city === '') {
      filters.city = undefined;
    }

    if (filters.maxPrice === undefined) {
      filters.maxPrice = Infinity;
    }

    const firstValidDate = new Date();
    firstValidDate.setDate(firstValidDate.getDate() - (30 * filters?.maxAgeMonths!));
    const lastValidDate = new Date();
    lastValidDate.setDate(lastValidDate.getDate() - (30 * filters?.minAgeMonths!));
    let snapshotSinglePosts: firebase.firestore.QuerySnapshot<unknown>;
    console.log('filters on de service', filters);
    snapshotSinglePosts = await this.firestore.collection(
      'post', ref => {

        console.log('first valid date', firstValidDate);
        console.log('last valid date', lastValidDate);

        let ans = ref.where('litter', '==', false);
        if (filters?.animal !== undefined) {
          ans = ans.where('animal', '==', filters.animal);
        }
        ans = ans.where('active', '==', true);

        if (filters?.breed !== undefined) {
          ans = ans.where('pet.breed', 'in', filters!.breed);
        }
        ans = ans.where('pet.birthDate', '>=', firstValidDate);
        ans = ans.where('pet.birthDate', '<=', lastValidDate);
        if (filters!.sex !== undefined) {
          ans = ans.where('pet.sex', '==', filters!.sex);
        }
        if (filters!.city !== undefined) {
          ans = ans.where('city', '==', filters!.city);
        }
        if (filters!.adopt == true) {
          ans = ans.where('adopt', '==', true);
        }

        return ans;

      }).
      get().toPromise();

    const postArray: Post[] = [];
    snapshotSinglePosts.docs.forEach(v => postArray.push(this.transformToPost(v.data())));
    console.log('post array', postArray);

    let snapshotLitterPosts;
    console.log(filters);

    console.log('maxAge months date', new Date(new Date().setMonth(new Date().getMonth() - filters!.maxAgeMonths!)));
    console.log('min age months date', new Date(new Date().setMonth(new Date().getMonth() - filters!.minAgeMonths!)));
    snapshotLitterPosts = await this.firestore.collection(
      'post', ref => {

        let ans = ref.where('litter', '==', true);
        ans = ans.where('active', '==', true);

        if (filters?.animal !== undefined) {
          ans = ans.where('animal', '==', filters.animal);
        }
        if (filters?.breed !== undefined) {
          ans = ans.where('breed', 'in', filters!.breed);
        }

        ans = ans.where('birthDate', '>=', firstValidDate);
        ans = ans.where('birthDate', '<=', lastValidDate);
        if (filters!.city !== undefined) {
          ans = ans.where('city', '==', filters!.city);
        }
        if (filters!.sex !== undefined && filters!.sex === 'male') {
          ans = ans.where('sexes', 'array-contains', 'male');
        }
        if (filters!.sex !== undefined && filters!.sex === 'female') {
          ans = ans.where('sexes', 'array-contains', 'female');
        }
        if (filters!.adopt == true) {
          ans = ans.where('adopt', '==', true);
        }
        return ans;

      }).
      get().toPromise();

    snapshotLitterPosts.docs.forEach(v => postArray.push(this.transformToPost(v.data())));

    console.log(postArray);
    return postArray;
  }


  /**
   * add the post to the favourite collection of a user
   * @param postId post id
   * @param userId user id
   */
  public async setAsFavourite(postId: string, userId: string): Promise<void> {
    console.log(userId);

    const userRef = this.firestore.collection('users').doc(userId);

    const unionRes = await userRef.update({
      favorites: firestoreApp.default.firestore.FieldValue.arrayUnion(postId)
    });
    return;
  }

  public async getFavoritePosts(user: User): Promise<Post[]> {
    if (user == null) {
      console.log('user is null');
    }
    const favorites: Post[] = [];
    console.log(user.favorites);

    user.favorites.forEach(async id => {
      const post = await this.getPostFromID(id);
      post !== undefined ? favorites.push(post!) : console.log('post ' + id + ' is undefined');
    });
    return favorites;
  }

  public async setAsNotFavorite(postId: string, userId: string): Promise<void> {
    const userRef = this.firestore.collection('users').doc(userId);

    const unionRes = await userRef.update({
      favorites: firestoreApp.default.firestore.FieldValue.arrayRemove(postId)
    });

    return;
  }

  public async getMyPosts(user: User): Promise<Post[]> {

    const myPosts: Post[] = [];
    console.log(user.posts);

    user.posts.forEach(async id => {
      console.log('id: ', id);
      const post = await this.getPostFromID(id);
      post !== undefined ? myPosts.push(post!) : console.log('post ' + id + ' is undefined');
    });
    console.log('my posts', myPosts);
    return myPosts;

  }

  public async addCode(code: string, codeInfo: any): Promise<void> {
    const userRef: AngularFirestoreDocument = this.firestore.doc(`codes/${code}`);

    return await userRef.set(codeInfo, { merge: true });
  }

  public async endPost(postID: string): Promise<void> {
    // Change code availability
    const codeRef: AngularFirestoreDocument<any> = this.firestore.doc(`post/${postID}`);
    return await codeRef.set({ active: false }, { merge: true });
  }


  public async addSeen(postId: string): Promise<void> {
    const postRef = this.firestore.collection('post').doc(postId);

    const updateRes = await postRef.update({
      views: firestoreApp.default.firestore.FieldValue.increment(1)

    });
    console.log('updateRes', updateRes);
    return;
  }
}
