<div [ngClass]="{'blured': loading}" fxLayout="column">
    <!--LOADING SPINNER-->
    <div *ngIf="loading" class="loading-area" fxLayout="row" fxLayoutAlign="center center">
        <mat-spinner></mat-spinner>
    </div>
    <app-nav-bar></app-nav-bar>

    <div class="container">
        <app-seller-info *ngIf="user" [user]="user" (continueEvent)="update()"></app-seller-info>
    </div>

</div>

