<div [ngClass]="{'blured': loading}" fxLayout="column">
    <!--LOADING SPINNER-->
    <div *ngIf="loading" class="loading-area" fxLayout="row" fxLayoutAlign="center center">
        <mat-spinner></mat-spinner>
    </div>
    <app-nav-bar></app-nav-bar>

    <mat-drawer-container class="footprint" [hasBackdrop]="innerWidth < 960" style="min-height: 800px;">
        <!--contains side bar and content-->
        <!--Side filter bar-->
        <mat-drawer [(opened)]="openDrawer" [mode]="innerWidth < 960 ? 'over' : 'side'" fxLayout="column"
            id="side-filters">

            <div fxLayout="column">
                <h2 *ngIf="selectedAnimal === 'dog'">Buscando perros</h2>
                <h2 *ngIf="selectedAnimal === 'cat'">Buscando gatos</h2>
                <button mat-raised-button color="primary" *ngIf="selectedAnimal === 'dog'" (click)="chooseBreed()">
                    Agregar raza de perro

                    <mat-icon matSuffix>pets</mat-icon>
                </button>
                <ul *ngIf="selectedAnimal =='dog'">
                    <mat-selection-list #dogBreedSelection
                        (selectionChange)="changedSelection(dogBreedSelection.selectedOptions.selected)">
                        <mat-list-option checkboxPosition="before" *ngFor="let breed of commonDogBreeds" [value]="breed"
                            [selected]="selectedBreeds.indexOf(breed) !== -1">
                            {{breed}}
                        </mat-list-option>
                    </mat-selection-list>
                </ul>

                <button mat-raised-button color="primary" *ngIf="selectedAnimal === 'cat'" (click)="chooseBreed()">
                    Agregar raza de gato

                    <mat-icon matSuffix>pets</mat-icon>
                </button>
                <ul *ngIf="selectedAnimal =='cat'">
                    <mat-selection-list #catBreedSelection
                        (selectionChange)="changedSelection(catBreedSelection.selectedOptions.selected)">
                        <mat-list-option *ngFor="let breed of commonCatBreeds" [value]="breed"
                            [selected]="selectedBreeds.indexOf(breed) !== -1">
                            {{breed}}
                        </mat-list-option>
                    </mat-selection-list>
                </ul>
                <button *ngIf="selectedAnimal !== 'dog'" mat-stroked-button (click)="selectDog()">Buscar perros</button>
                <button mat-stroked-button *ngIf="selectedAnimal !== 'cat'" (click)="selectCat()">Buscar gatos</button>
            </div>
            <h3>Sexo</h3>
            <section>
                <mat-radio-group class="example-radio-group" [(ngModel)]="selectedSex" fxLayout="column"
                    (ngModelChange)="search()">
                    <mat-radio-button value="male">macho</mat-radio-button>
                    <mat-radio-button value="female">hembra</mat-radio-button>
                    <mat-radio-button [value]="undefined">cualquiera</mat-radio-button>
                </mat-radio-group>

            </section>
            <h3>Edad</h3>
            <section fxLayout="column">
                <mat-radio-group class="example-radio-group" [(ngModel)]="selectedAge" fxLayout="column"
                    (ngModelChange)="search()">
                    <mat-radio-button [value]="undefined">cualquiera</mat-radio-button>
                    <mat-radio-button [value]="{minAgeMonths: 0, maxAgeMonths: 3}">0-3 meses</mat-radio-button>
                    <mat-radio-button [value]="{minAgeMonths: 3, maxAgeMonths: 12}">3-12 meses</mat-radio-button>
                    <mat-radio-button [value]="{minAgeMonths: 12, maxAgeMonths: 36}">1-3 años</mat-radio-button>
                    <mat-radio-button [value]="{minAgeMonths: 48, maxAgeMonths: 100000}">4 años en adelante
                    </mat-radio-button>
                </mat-radio-group>
            </section>

            <h3>Ciudad</h3>
            <section>
                <mat-radio-group class="example-radio-group" [(ngModel)]="selectedCity" fxLayout="column"
                    (ngModelChange)="search()">
                    <mat-radio-button value="cualquiera">cualquiera</mat-radio-button>
                    <mat-radio-button *ngFor="let city of commonCities" [value]="city">
                        {{city}}
                    </mat-radio-button>
                </mat-radio-group>

                <button mat-flat-button color="primary" (click)="chooseCity()" style="margin-top: 10px;">
                    Otra ciudad
                    <mat-icon mat-suffix>add</mat-icon>
                </button>
            </section>

            <mat-checkbox [(ngModel)]="onlyAdopt" (change)="search()" style="margin-top: 10px;"> Solo en adopción
            </mat-checkbox>
        </mat-drawer>

        <div class="container">
            <div fxHide fxShow.lt-md fxLayout="row" fxLayoutAlign="end center" style="margin-bottom: 10px;">
                <button mat-stroked-button (click)=" toggleDrawer()">
                    <span>Filtrar</span>
                    <mat-icon>filter_list</mat-icon>
                </button>
            </div>
            <div fxLayout="row wrap" fxLayoutGap="8px grid" fxLayoutAlign="space-around stretch">
                <div fxFlex="25%" fxFlex.md="33%" fxFlex.xs="90%" fxFlex.sm="40%" *ngFor="let post of posts"
                    fxLayoutAlign.xs="center center">
                    <app-post-card [isFavorite]="isFavorite(post.id)" [post]="post"
                        (eliminateFavorite)="setAsNotFavorite(post.id)" (favourite)="setAsFavourite(post.id)"
                        (selected)="goToPost(post.id)"></app-post-card>
                </div>
                <!-- in case there are no results for the search -->
                <div fxFlex="100%" fxLayout="row" fxLayoutAlign="center center" *ngIf="posts && posts.length === 0">
                    <mat-card>
                        <mat-card-title>No se encontraron resultados</mat-card-title>
                        <mat-card-content>
                            <p>
                                No se encontró ninuguna mascota que coincida con los filtros seleccionados.
                            </p>
                            <p>Intenta modificar los filtros para hacer más amplia la búsqueda.</p>
                        </mat-card-content>
                    </mat-card>
                </div>
                <!--to fill the grid in case there is less than one row of posts in the results-->
                <div fxHide fxShow.gt-xs fxFlex="25%" fxFlex.xs="90%" fxFlex.sm="55%" *ngFor="let number of [0,1,2,3,4]"
                    class="post-card fill-card">
                </div>
            </div>

        </div>
        <app-footer></app-footer>
    </mat-drawer-container>

</div>