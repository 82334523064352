<div [ngClass]="{'blured': loading}">
    <app-nav-bar></app-nav-bar>



    <div *ngIf="loading" class="loading-area" fxLayout="row" fxLayoutAlign="center center">
        <mat-card fxLayout="column" fxLayoutAlign="space-around stretch" class="loading-card">
            <pre>{{message}}</pre>

            <mat-progress-bar mode="determinate" [value]="progressValue"></mat-progress-bar>
        </mat-card>
    </div>

    <div fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-around stretch" fxLayout="column" fxLayoutAlign="start center"
        class="container">

        <mat-horizontal-stepper labelPosition="bottom" [linear]="true" id="stepper" class="box_container"
            fxFlex.gt-sm="60%" [ngClass.lt-sm]="'container-mobile'">

            <!-- FIRST STEP -->
            <mat-step [completed]="false">
                <ng-template matStepLabel>Tipo</ng-template>
                <div fxLayout.gt-sm="row" fxLayout="column" fxLayoutAlign="space-between ">

                    <div fxLayout="column" fxLayoutAlign="space-around start" class="pub-type-info"
                        (click)="continueWithPet()">
                        <h1>Publicación de una mascota</h1>
                        <div fxLayout="row" fxLayoutAlign="center center" style="width: 100%;">
                            <img [src]="mascotaPath" [alt]="'mascota'" width="200px">
                        </div>
                        <span>Puedes subir hasta 5 fotos del cachorro</span>
                        <span>Tu publicación estará activa por 2 meses</span>
                        <h1>$15.000 COP</h1>
                        <span>cargo por la publicación</span>
                    </div>


                    <mat-divider fxShow fxHide.lt-md vertical style="height:300px;"></mat-divider>

                    <div fxLayout="column" fxLayoutAlign="space-around start" class="pub-type-info"
                        (click)="continueWithLitter()">
                        <h1>Publicación de una camada</h1>
                        <div fxLayout="row" fxLayoutAlign="center center" style="width: 100%;">
                            <img [src]="camadaPath" [alt]="'camada'" width="200px">
                        </div>
                        <span>Puedes subir hasta 10 fotos de la camada</span>
                        <span>Puedes subir hasta 2 fotos de cada cachorro</span>
                        <span>Tu publicación estará activa por 3 meses</span>
                        <h1>$40.000 COP</h1>
                        <span>cargo por la publicación</span>
                    </div>
                </div>

            </mat-step>

            <!-- SECOND STEP-->
            <mat-step [completed]="false">
                <ng-template matStepLabel>Especie</ng-template>
                <h1>De qué especie es?</h1>
                <div fxLayout.gt-sm="row" fxLayout="column" fxLayoutAlign="space-between center">
                    <div fxLayout="column" fxLayoutAlign="space-around start" class="pub-type-info"
                        (click)="continueWithDog()">
                        <img [src]="dogIconPath" [alt]="'perro'" width="200px">
                    </div>

                    <div fxLayout="column" fxLayoutAlign="space-around start" class="pub-type-info"
                        (click)="continueWithCat()">
                        <img [src]="catIconPath" [alt]="'perro'" width="200px">
                    </div>
                </div>

            </mat-step>

            <!--THIRD STEP-->
            <mat-step [completed]="false">
                <ng-template matStepLabel>Info</ng-template>
                <app-add-pet *ngIf="type === 'single'" [myPost]="$any(myPost)" [animal]="myPost.animal"
                    (isValidForm)="setValidFormInfo($event)">
                </app-add-pet>
                <app-add-litter *ngIf="type === 'litter'" [myPost]="litterPost" [animal]="myPost.animal"
                    (isValidForm)="setValidFormInfo($event)"
                    (childrenImagesFilesChanges)="updateChildrenImages($event)"></app-add-litter>

                <div fxLayout="row" fxLayoutAlign="end center" fxFlex="100%">
                    <button mat-raised-button color="primary" [disabled]="!isValidFormInfo"
                        (click)="continue()">Continuar</button>
                </div>
            </mat-step>

            <!--FOURTH STEP-->
            <mat-step [completed]="false">
                <ng-template matStepLabel>Info. vendedor</ng-template>

                <app-seller-info *ngIf="changedUser" [user]="changedUser" (continueEvent)="continue()">
                </app-seller-info>

            </mat-step>

            <!--FIFTH STEP-->
            <mat-step [completed]="false">
                <ng-template matStepLabel>Fotos</ng-template>

                <h1>Foto de portada</h1>
                <div class="dropzone" appDropzone (hovered)="toggleHoverCover($event)" (dropped)="onDropCover($event)"
                    fxLayout="column" fxLayoutAlign="center center" [ngClass]="{'dropzone-hover': hoveringCover}">
                    <p>arrastra aquí la foto principal de la publicación</p>

                    <p>O haz click aquí para seleccionar un archivo</p>

                    <!--<input type="file" (change)="onSelectSingleFile($event)"> -->

                    <button mat-mini-fab color="accent" type="button" (click)="coverFileInput.click()">
                        <mat-icon>add</mat-icon>
                        <input #coverFileInput type="file" accept=".jpg,.jpeg,.png,.webp"
                            (change)="onSelectSingleFile($event)" style="display:none;" />
                    </button>
                    <img *ngIf="coverFoto" [src]="myPost.picture" height="100">
                </div>

                <h1 *ngIf="type === 'single' ">Galería de fotos</h1>
                <div *ngIf="type === 'single' " class="dropzone" appDropzone
                    (hovered)="toggleHover($event, hoveringGallery)" (dropped)="onDrop($event)" fxLayout="column"
                    fxLayoutAlign="center center" [ngClass]="{'dropzone-hover': hoveringGallery}">
                    <p>arrastra aquí las fotos </p>

                    <p>O haz click para agregar fotos</p>

                    <p>Puedes agregar hasta 5 fotos</p>

                    <!--botn para agregar fotos-->
                    <button mat-mini-fab color="accent" type="button" (click)="galleryFileInput.click()">
                        <mat-icon>add</mat-icon>
                        <input #galleryFileInput type="file" accept=".jpg,.jpeg,.png,.webp"
                            (change)="onSelectFiles($event)" style="display:none;" multiple />
                    </button>

                    <div class="gallery" fxLayout="row" fxLayout.lt-sm="column">
                        <img *ngFor="let photo of $any(myPost).pet.pictures" [src]="photo" height="100"
                            class="gallery_preview_photo">
                    </div>

                </div>



                <div fxLayout="row" fxLayoutAlign="end center" fxFlex="100%">
                    <button mat-raised-button color="primary" [disabled]="!coverFoto"
                        (click)="continue()">Continuar</button>
                </div>

            </mat-step>

            <!--SIXTH STEP-->
            <mat-step [completed]="false" fxLayout="column" fxLayoutAlign="space-around start">
                <ng-template matStepLabel>Padres</ng-template>

                <div fxLayout.gt-sm="row" fxLayout="column" fxLayoutAlign="space-between ">
                    <div fxFlex="45%">
                        <h2>Padre</h2>

                        <mat-form-field required appearance="outline" style="width: 100%">
                            <mat-label>Nombre</mat-label>
                            <input matInput [(ngModel)]="myPost.father!.name">
                        </mat-form-field>

                        <app-breed-autocomplete [animal]="myPost.animal" ngDefaultControl [cualquiera]="false"
                            (breedChange)="myPost.father!.breed = $event">
                        </app-breed-autocomplete>


                        <div class="dropzone parent-dropzone" appDropzone
                            (hovered)="toggleHover($event, hoveringFather)" (dropped)="onDropParent($event, 'father')"
                            fxLayout="column" fxLayoutAlign="center center"
                            [ngClass]="{'dropzone-hover': hoveringFather}">
                            Carga o arrastra la foto del padre aquí

                            <button mat-mini-fab color="accent" type="button" (click)="fatherFileInput.click()">
                                <mat-icon *ngIf="!fatherFoto">add</mat-icon>
                                <mat-icon *ngIf="fatherFoto">edit</mat-icon>

                                <input #fatherFileInput type="file" accept=".jpg,.jpeg,.png,.webp"
                                    (change)="onSelectFileParent($event, 'father')" style="display:none;" />
                            </button>
                            <img *ngIf="fatherFoto" [src]="myPost.father?.image" height="90%">
                        </div>
                    </div>

                    <mat-divider fxShow fxHide.lt-md vertical style="height:500px;"></mat-divider>

                    <div fxFlex="45%">
                        <h2>Madre</h2>

                        <mat-form-field required appearance="outline" style="width: 100%;">
                            <mat-label>Nombre</mat-label>
                            <input matInput [(ngModel)]="myPost.mother!.name">
                        </mat-form-field>

                        <app-breed-autocomplete [animal]="myPost.animal" ngDefaultControl [cualquiera]="false"
                            (breedChange)="myPost.mother!.breed = $event">
                        </app-breed-autocomplete>


                        <div class="dropzone parent-dropzone" appDropzone
                            (hovered)="toggleHover($event, hoveringMother)" (dropped)="onDropParent($event, 'mother')"
                            fxLayout="column" fxLayoutAlign="center center"
                            [ngClass]="{'dropzone-hover': hoveringMother}">
                            Carga o arrastra la foto de la madre aquí

                            <button mat-mini-fab color="accent" type="button" (click)="motherFileInput.click()">
                                <mat-icon *ngIf="!motherFoto">add</mat-icon>
                                <mat-icon *ngIf="motherFoto">edit</mat-icon>
                                <input #motherFileInput type="file" accept=".jpg,.jpeg,.png,.webp"
                                    (change)="onSelectFileParent($event, 'mother')" style="display:none;" />
                            </button>
                            <img *ngIf="motherFoto" [src]="myPost.mother?.image" height="90%">
                        </div>
                    </div>

                </div>

                <div fxLayout="row" fxLayoutAlign="end center" fxFlex="100%">
                    <button mat-raised-button color="primary" (click)="submitInfo()"> Enviar</button>
                </div>



            </mat-step>


        </mat-horizontal-stepper>
        <!--
        <mat-divider [vertical]="true"></mat-divider>


        <div fxLayout="column" fxLayoutAlign="start center" fxFlex="50%">
            <h1>
                así quedará tu publicación:
            </h1>
            <app-post-card fxFlex="33%"  [post]="myPost"></app-post-card>

        </div>
    </div>
-->
    </div>