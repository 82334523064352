<div [ngClass]="{'blured': loading}">


    <!--LOADING SPINNER-->
    <div *ngIf="loading" class="loading-area" fxLayout="row" fxLayoutAlign="center center">
        <mat-spinner></mat-spinner>
    </div>
    <app-nav-bar></app-nav-bar>

    <!--POSTS-->
    <div class="container">

        <h1>Favoritos</h1>

    </div>
    <div fxLayout="row wrap" fxLayoutGap="16px grid" class="container">

        <div fxFlex="25%" fxFlex.xs="90%" fxFlex.sm="50%" *ngFor="let post of favoritePosts">
            <app-post-card [isFavorite]="isFavorite(post.id)" [post]="post"
                (eliminateFavorite)="setAsNotFavorite(post.id)" (favourite)="setAsFavourite(post.id)"
                (selected)="goToPost(post.id)"></app-post-card>
        </div>


    </div>



</div>