<mat-form-field appearance="outline">
    <mat-label>Raza</mat-label>
    <mat-chip-list #chipList aria-label="Fruit selection">
        <mat-chip *ngFor="let breed of breeds" (removed)="remove(breed)">
            {{breed}}
            <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
        <input placeholder="Otra raza" #breedInput [formControl]="breedCtrl" [matAutocomplete]="auto"
            [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            (matChipInputTokenEnd)="add($event)">
    </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
        <mat-option *ngFor="let b of filteredBreeds | async" [value]="b">
            {{b}}
        </mat-option>
    </mat-autocomplete>
</mat-form-field>

<mat-form-field appearance="outline">
    <mat-label>Ciudad</mat-label>
    <input matInput type="text" [(ngModel)]="city">
</mat-form-field>

<mat-button-toggle-group [(ngModel)]="sex" name="fontStyle" aria-label="Font Style">
    <mat-button-toggle value="male">Macho</mat-button-toggle>
    <mat-button-toggle value="female">Hembra</mat-button-toggle>
    <mat-button-toggle [value]="undefined">Cualquiera</mat-button-toggle>
</mat-button-toggle-group>

<div fxLayout="column" fxLayoutAlign="center center">
    <mat-label>Precio máximo</mat-label>
    <mat-slider [disabled]="false" [invert]="false" [max]="maxPrice" [min]="0" [step]="priceStep" [thumbLabel]="false"
        [(ngModel)]="price" [vertical]="false" aria-labelledby="example-name-label" (input)="updateBudget($event)">
    </mat-slider>

    <p>
        {{displayPrice | currency:'$':'symbol':'4.0'}}
    </p>

</div>
<button mat-raised-button color="primary" (click)="queryPosts()">Buscar</button>