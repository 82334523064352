import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-breeds-dialog',
  templateUrl: './breeds-dialog.component.html',
  styleUrls: ['./breeds-dialog.component.scss']
})
export class BreedsDialogComponent implements OnInit {

  public loading = true;
  public dogBreeds: any[];
  public catBreeds: any[];
  public tabIndex = 0;

  constructor(private utils: UtilsService,
    private dialogRef: MatDialogRef<BreedsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  public tabIntex = 0;

  public getImageURLFromBreed(breed: string): string {
    return 'https://firebasestorage.googleapis.com/v0/b/pet-sale-73f4e.appspot.com/o/breeds%2Fdogs%2F' + breed.toLowerCase() + '.jpg?alt=media&token=504024ae-cd5e-48a6-96df-804261b7ff20';
  }

  public chooseBreed(breed, animal): void {
    this.dialogRef.close({ animal, breed });
  }

  ngOnInit(): void {
    console.log('in on init: ', this.data);
    this.utils.getDogsBreedsInfo().then((breeds) => {
      console.log(breeds);
      this.dogBreeds = breeds;
      this.dogBreeds.sort((a, b) => {
        return a.breed < b.breed ? -1 : 1;
      });
      this.loading = false;
    });


    this.utils.getCatsBreedsInfo().then((breeds) => {
      console.log(breeds);
      this.catBreeds = breeds;
      this.catBreeds.sort((a, b) => {
        return a.breed < b.breed ? -1 : 1;
      });
      this.loading = false;
    });
  }

}
