<mat-tab-group mat-align-tabs="start" class="breed-dialog-container" [animationDuration]="0"
    [(selectedIndex)]="tabIndex">
    <mat-tab label="Perros" *ngIf="data.animal === 'dog'">
        <div *ngFor="let breed of dogBreeds" fxLayout="row" fxLayoutAlign="start center" class="breed-chooser"
            (click)="chooseBreed(breed.breed, 'dog')">
            <img [src]="breed.image" width="100" class="breed-description-img">
            <div>
                <h2>{{breed.breed}}</h2>
                <p>{{breed.size}}</p>
            </div>
        </div>
    </mat-tab>
    <mat-tab label="Gatos" *ngIf="data.animal === 'cat'">
        <div *ngFor="let breed of catBreeds" fxLayout="row" fxLayoutAlign="start center" class="breed-chooser"
            (click)="chooseBreed(breed.breed, 'cat')">
            <img [src]="breed.image" width="100" class="breed-description-img">
            <div>
                <h2>{{breed.breed}}</h2>
                <p>{{breed.size}}</p>

            </div>
        </div>
    </mat-tab>
</mat-tab-group>