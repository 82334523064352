import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Filters } from 'src/app/models/filters';
import { Post } from 'src/app/models/post';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { DatabaseServiceService } from 'src/app/services/database-service.service';

@Component({
  selector: 'app-post-list',
  templateUrl: './post-list.component.html',
  styleUrls: ['./post-list.component.scss']
})
export class PostListComponent implements OnInit {

  public posts: Post[];
  public loading = false;
  public user: User;

  constructor(private db: DatabaseServiceService, private router: Router, private auth: AuthService) {
    this.auth.user$.subscribe(u => {
      this.user = u;
      // console.log(this.user);

    });
  }

  public goToPost(postId: string): void {
    this.router.navigate(['/post_info', postId]);
  }

  public async setAsFavourite(id: string): Promise<void> {
    this.loading = true;
    console.log(this.user);
    // user is logged in
    if (this.user !== null) {
      await this.db.setAsFavourite(id, this.user.uid);
      this.loading = false;
    }

    else {
      console.log("take to login");
      this.loading = false;
      this.router.navigate(['/login']);
    }
  }

  public async search(filters: Filters): Promise<void> {
    console.log(filters);
    this.loading = true;
    this.posts = await this.db.queryPosts(filters);
    this.loading = false;
  }

  public isFavorite(postId: string): boolean {
    if (this.user === null) {
      return false;
    }
    const ans = this.user.favorites.indexOf(postId) !== -1;

    return ans;
  }

  public async setAsNotFavorite(postId: string): Promise<void> {
    this.loading = true;
    await this.db.setAsNotFavorite(postId, this.user.uid);
    this.loading = false;

  }

  /**
   * sorts the lists of posts
   * @param value parameter to sort the posts by
   */
  public onValChange(value): void {

    if (value === 'relevance') {
      this.posts.sort((obj1, obj2) => {
        if (obj1.creationDate < obj2.creationDate) {
          return 1;
        }
        else {
          return -1;
        }
      });
    }
    else if (value === 'priceAscending') {
      this.posts.sort((obj1, obj2) => {
        if ((obj1 as any).price > (obj2 as any).price) {
          return 1;
        }
        else {
          return -1;
        }
      });
    }
    else if (value === 'priceDescending') {
      this.posts.sort((obj1, obj2) => {
        if ((obj1 as any).price < (obj2 as any).price) {
          return 1;
        }
        else {
          return -1;
        }
      });
    }
  }

  ngOnInit(): void {
    this.loading = true;
    this.db.queryPosts().then(posts => {
      this.posts = posts;
      console.log(posts);
      this.loading = false;
    });
  }
}
