import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {


  @Input() large = false;

  public isSignedIn = false;
  constructor(private router: Router, private authService: AuthService) {
    console.log(authService.user$);
    authService.user$.subscribe(u => this.isSignedIn = u != null);
  }

  public navigateHome(): void {
    this.router.navigate(['']);
  }

  public createPost(): void {
    this.router.navigate(['create_post']);
  }

  public signOut(): void {
    this.authService.signOut();
  }

  public goToFavorites(): void {
    this.router.navigate(['favorites']);
  }

  public myPosts(): void {
    this.router.navigate(['my_posts']);
  }

  public goToProfile(): void {
    this.router.navigate(['profile']);
  }

  public signIn(): void {
    this.router.navigate(['login']);

  }

  ngOnInit(): void {
  }

}
