import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Litter, Post, Single } from 'src/app/models/post';
import { MatHorizontalStepper } from '@angular/material/stepper';
import { DatabaseServiceService } from 'src/app/services/database-service.service';
import { AuthService } from 'src/app/services/auth.service';
import { User } from 'src/app/models/user';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-create-post',
  templateUrl: './create-post.component.html',
  styleUrls: ['./create-post.component.scss']
})
export class CreatePostComponent implements OnInit {

  public dogIconPath = '/assets/icons/Icono_perro_negro.png';
  public catIconPath = '/assets/icons/Icono_gato_negro.png';
  public mascotaPath = '/assets/images/Mascota.png';
  public camadaPath = '/assets/images/Camada.png';
  public originalUser: User;
  public changedUser: User;
  public isValidFormInfo = false;
  public type = 'single';

  public myPost: Post;


  public singlePost: Single = {
    id: '',
    adopt: false,
    creator: '',
    city: '',
    active: false,
    creationDate: new Date(),
    expirationDate: new Date(),
    description: '',
    litter: false,
    views: 0,
    type: 'sell',
    payed: false,
    price: 0,
    animal: 'dog',
    pet: {
      sex: '',
      certificates: [],
      vaccines: [],
      breed: 'Raza',
      birthDate: new Date(),
      pictures: []
    },
    mother: {
      name: '',
      image: '',
      breed: ''
    },
    father: {
      name: '',
      image: '',
      breed: ''
    },
    picture: 'https://images.dog.ceo/breeds/wolfhound-irish/n02090721_1688.jpg'

  };
  public litterPost: Litter = {
    id: '',
    adopt: false,
    creator: '',
    city: '',
    breed: '',
    active: false,
    creationDate: new Date(),
    expirationDate: new Date(),
    description: '',
    litter: true,
    views: 0,
    type: 'sell',
    payed: false,
    animal: 'dog',
    pets:
      [{
        sex: '',
        certificates: [],
        vaccines: [],
        breed: 'Raza',
        birthDate: new Date(),
        pictures: []
      }]
    ,
    mother: {
      name: '',
      image: '',
      breed: ''
    },
    father: {
      name: '',
      image: '',
      breed: ''
    },
    picture: 'https://images.dog.ceo/breeds/wolfhound-irish/n02090721_1688.jpg'

  };


  // image upload
  public hoveringCover: boolean;
  public hoveringGallery: boolean;


  public hoveringFather: boolean;
  public hoveringMother: boolean;
  public files: File[] = [];
  public coverFoto: File;

  public fatherFoto: File;
  public motherFoto: File;

  public loading = false;
  public message: string;
  public progressValue = 0;

  @ViewChild('vaccineInput') vaccineInput: ElementRef<HTMLInputElement> | undefined;
  @ViewChild(MatHorizontalStepper)
  stepper!: MatHorizontalStepper;


  constructor(private db: DatabaseServiceService, private auth: AuthService, private router: Router, private snackBar: MatSnackBar) {
    this.myPost = this.singlePost;
    this.auth.user$.subscribe(u => {
      this.originalUser = u;
      this.changedUser = Object.assign({}, u);
      this.myPost.creator = u.uid;
    });
  }

  public setValidFormInfo(event): void {
    this.isValidFormInfo = event;
  }
  // stepper
  public continue(): void {
    this.stepper.selected.completed = true;
    console.log('on continues: ', this.myPost);
    this.stepper?.next();
  }

  public continueWithPet(): void {
    this.type = 'single';
    this.myPost = this.singlePost;
    this.continue();
  }

  public continueWithLitter(): void {
    this.type = 'litter';
    this.myPost = this.litterPost;
    this.continue();

  }

  public continueWithDog(): void {
    this.myPost.animal = 'dog';
    this.continue();
  }

  public continueWithCat(): void {
    this.myPost.animal = 'cat';
    this.continue();
  }

  // image upload

  toggleHover(event, hovering): void {
    hovering = event;
    console.log(hovering);
    this.hoveringGallery = event;
  }

  onDrop(files: any): void {

    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < files.length; i++) {
      this.files.push(files.item(i)!);
    }

    this.updateGalleryPreview();
  }

  onSelectFiles(event): void {

    if (event.target.files.length + this.files.length > 5) {
      this.openSnackBar('Solo se pueden subir 5 fotos');
      return;
    }
    if (event.target.files && event.target.files.length) {

      const files = event.target.files;
      for (let i = 0; i < files.length; i++) {
        this.files.push(files.item(i)!);
      }
      this.updateGalleryPreview();
    }
  }

  updateGalleryPreview(): void {

    for (let i = 0; i < this.files.length; ++i) {
      const reader = new FileReader();

      reader.readAsDataURL(this.files[i]);

      reader.onload = () => {
        // tslint:disable-next-line: no-non-null-assertion
        (this.myPost as Single).pet.pictures![i] = reader.result as string;
      };
    }
  }

  toggleHoverCover(event): void {
    this.hoveringCover = event;
  }

  onDropCover(files: FileList): void {

    // tslint:disable-next-line: no-non-null-assertion
    this.coverFoto = files.item(files.length - 1)!;
    console.log(this.coverFoto);

    this.updateCoverPicture();

  }

  onSelectSingleFile(event): void {
    if (event.target.files && event.target.files.length) {

      const files = event.target.files;
      // tslint:disable-next-line: no-non-null-assertion
      this.coverFoto = files.item(files.length - 1)!;
      console.log(this.coverFoto);
      this.updateCoverPicture();
    }
  }

  updateCoverPicture(): void {

    const reader = new FileReader();

    reader.readAsDataURL(this.coverFoto);

    reader.onload = () => {
      this.myPost.picture = reader.result as string;
    };
  }

  onDropParent(files: FileList, parent: string): void {

    if (parent === 'father') {
      // tslint:disable-next-line: no-non-null-assertion
      this.fatherFoto = files.item(files.length - 1)!;
      this.updateFatherPicture();
    }
    else {
      // tslint:disable-next-line: no-non-null-assertion
      this.motherFoto = files.item(files.length - 1)!;
      this.updateMotherPicture();
    }
  }

  onSelectFileParent(event, parent): void {
    console.log('inside method');
    if (event.target.files) {
      console.log('inside first if');
      const files = event.target.files;

      if (parent === 'father') {
        console.log('inside father');
        this.fatherFoto = files.item(files.length - 1)!;
        this.updateFatherPicture();
      }

      else if (parent === 'mother') {
        console.log('inside mother');
        this.motherFoto = files.item(files.length - 1)!;
        this.updateMotherPicture();
      }

    }


  }
  private updateMotherPicture(): void {

    // mother
    const reader = new FileReader();

    reader.readAsDataURL(this.motherFoto);

    if (!this.myPost.mother) {
      this.myPost.mother = { name: '', image: '', breed: '' };
    }
    reader.onload = () => {
      this.myPost.mother!.image = reader.result as string;
    };

  }
  private updateFatherPicture(): void {


    // father
    const reader = new FileReader();
    reader.readAsDataURL(this.fatherFoto);


    if (!this.myPost.father) {
      this.myPost.father = { name: '', image: '', breed: '' };
    }
    reader.onload = () => {
      this.myPost.father!.image = reader.result as string;
    };
  }


  private getProgressStep(): number {
    let numPictures = 0;

    if (this.type === 'single') {
      numPictures += (this.myPost as Single).pet.pictures!.length;
    }
    else {
      numPictures += (this.myPost as Litter).pets.length;
    }

    if (this.motherFoto) {
      numPictures++;
    }

    if (this.fatherFoto) {
      numPictures++;
    }

    console.log('num pictures: ' + numPictures);

    return 100 / numPictures;

  }

  private async uploadImagesSingle(postID, progressStep): Promise<void> {
    this.message = 'Subiendo foto de portada';

    // upload images to storage and get download links
    this.myPost.picture = await this.db.uploadImage(postID, this.coverFoto, 'cover');

    if (this.motherFoto) {
      this.message = 'subiendo foto de la madre';
      this.myPost.mother!.image = await this.db.uploadImage(postID, this.motherFoto, 'mother');
      this.progressValue += progressStep;
    }
    if (this.fatherFoto) {
      this.message = 'subiendo foto del padre';
      this.myPost.father!.image = await this.db.uploadImage(postID, this.fatherFoto, 'father');
      this.progressValue += progressStep;

    }

    console.log(this.files);

    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.files.length; ++i) {
      (this.myPost as Single).pet.pictures?.push('');
    }

    // TODO: pet.pictures array should have only the url to firebase file, for some reason the array has twice the elements it should have
    // the nex for loop eliminates the empty strings that are in the array

    for (let i = 0; i < this.files.length; ++i) {
      this.message = 'Subiendo foto #' + (i + 1);
      const url = await this.db.uploadImage(postID, this.files[i], i.toString());
      this.progressValue += progressStep;
      (this.myPost as Single).pet.pictures![i] = url;
    }

    // this block of code eliminates empty strings from the array
    (this.myPost as Single).pet.pictures!.forEach((value, index) => {
      if (value === '') {
        (this.myPost as Single).pet.pictures!.splice(index, 1);
      }
    });
  }


  private async uploadImagesMultiple(postID, progressStep): Promise<void> {
    this.message = 'Subiendo foto de portada';

    // upload images to storage and get download links
    this.myPost.picture = await this.db.uploadImage(postID, this.coverFoto, 'cover');

    if (this.motherFoto) {
      this.message = 'subiendo foto de la madre';
      this.myPost.mother!.image = await this.db.uploadImage(postID, this.motherFoto, 'mother');
      this.progressValue += progressStep;
    }
    if (this.fatherFoto) {
      this.message = 'subiendo foto del padre';
      this.myPost.father!.image = await this.db.uploadImage(postID, this.fatherFoto, 'father');
      this.progressValue += progressStep;

    }

    for (let i = 0; i < this.files.length; ++i) {
      this.message = 'Subiendo foto #' + (i + 1);
      const url = await this.db.uploadImage(postID, this.files[i], i.toString());
      this.progressValue += progressStep;
      (this.myPost as Litter).pets[i].pictures![0] = url;
    }
  }
  public async submitInfo(): Promise<void> {

    const progressStep = this.getProgressStep();

    this.loading = true;
    const postID = this.db.createID();

    this.myPost.id = postID;

    if (this.myPost.litter === false) {
      console.log('uploading single images');
      await this.uploadImagesSingle(postID, progressStep);
    }
    else {
      console.log('uploading multiple images');
      await this.uploadImagesMultiple(postID, progressStep);
    }
    // Modify user info on database
    this.message = 'Actualizando información del usuario';

    this.changedUser.posts.push(postID);
    this.db.updateUserData(this.changedUser);


    // City field is duplicated on the database (post and user)  to make queries faster
    this.myPost.city = this.changedUser.city!;


    this.message = 'Creando publicación';

    // assigns user id to post object
    this.myPost.creator = this.originalUser.uid;

    console.log('my post: ', this.myPost);
    if (this.myPost.litter === false) {
      await this.db.createPostSingle(this.myPost as Single, postID);
    }
    else {
      await this.db.createPostLitter(this.myPost as Litter, postID);
    }

    this.loading = false;
    this.progressValue = 0;

    // redirect to payment

    this.router.navigate(['/pay', postID]);

  }

  public updateChildrenImages(event: any): void {
    console.log(event);
    this.files = event;
  }

  public changeOnFather(e): void {
    console.log(e);
  }

  private openSnackBar(message: string): void {
    this.snackBar.open(message, 'Ok');
  }



  ngOnInit(): void {
  }

}


