import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips/chip-input';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/internal/operators/map';
import { startWith } from 'rxjs/internal/operators/startWith';
import { Single } from 'src/app/models/post';
import { DatabaseServiceService } from 'src/app/services/database-service.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-edit-single',
  templateUrl: './edit-single.component.html',
  styleUrls: ['./edit-single.component.scss']
})
export class EditSingleComponent implements OnInit {

  public separatorKeysCodes: number[] = [ENTER, COMMA];

  public loading = false;
  public myPost: Single;
  public message = 'asdfasdfads';
  @Input() post: Single;
  @ViewChild('vaccineInput') vaccineInput: ElementRef<HTMLInputElement> | undefined;
  public selectedVaccines: string[] = [];
  public filteredVaccines: Observable<string[]>;
  public commonVaccines: string[];

  public isValidForm = false;
  public hoveringCoverImage = false;
  public hoveringGalleryImage: boolean[] = [];
  public coverFoto: File;
  public petImages: string[] = [];

  public progressValue = 0;

  public files = {};

  breedCtrl = new FormControl('', [Validators.required]);

  petInfoForm = new FormGroup({
    birthCtrl: new FormControl('', [Validators.required]),
    descriptionCtrl: new FormControl('', [Validators.required]),
    vaccineCtrl: new FormControl(),
    breedCtrl: this.breedCtrl,
    priceCtrl: new FormControl('', [Validators.required]),
  });

  constructor(private utils: UtilsService, private db: DatabaseServiceService, private router: Router) {
    this.utils.getDogVaccines().then(vac => {
      this.commonVaccines = vac;
      this.filteredVaccines = this.petInfoForm.controls.vaccineCtrl.valueChanges.pipe(
        startWith(null),
        map((vacc: string | null) => (vacc ? this._filter(vacc) : this.commonVaccines.slice())),
      );
    });
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.commonVaccines.filter(vaccine => vaccine.toLowerCase().includes(filterValue));
  }
  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    if (value) {
      this.selectedVaccines.push(value);
    }

    // Clear the input value
    if (event.input) {
      event.input.value = '';
    }

    this.petInfoForm.controls.vaccineCtrl.setValue(null);
  }
  remove(vaccine: string): void {
    const index = this.selectedVaccines.indexOf(vaccine);

    if (index >= 0) {
      this.selectedVaccines.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.selectedVaccines.push(event.option.viewValue);
    this.vaccineInput!.nativeElement.value = '';
    this.petInfoForm.controls.vaccineCtrl.setValue(null);
  }



  public editCoverPhoto(event): void {
    if (event.target.files && event.target.files.length) {
      const files = event.target.files;
      // tslint:disable-next-line: no-non-null-assertion
      this.coverFoto = files.item(files.length - 1)!;
      const reader = new FileReader();
      reader.readAsDataURL(this.coverFoto);
      reader.onload = () => {
        this.myPost.picture = reader.result as string;
      };
    }
    this.validate();
  }

  public editGalleryFoto(event, i): void {
    if (event.target.files && event.target.files.length) {
      const files = event.target.files;
      // tslint:disable-next-line: no-non-null-assertion
      this.files[i] = files.item(files.length - 1)!;
      const reader = new FileReader();
      reader.readAsDataURL(this.files[i] as File);
      reader.onload = () => {
        this.petImages[i] = reader.result as string;
      };
    }
    this.validate();
  }

  private getProgressStep(): number {
    return 100 / (this.myPost!.pet!.pictures!.length + 1);
  }

  public async savePost(): Promise<void> {
    this.validate();
    if (this.isValidForm) {
      const progressStep = this.getProgressStep();
      this.loading = true;
      const postID = this.myPost.id;
      await this.uploadImages(postID, progressStep);
      this.message = 'Creando publicación';

      console.log(this.myPost);
      await this.db.createPostSingle(this.myPost as Single, postID);
      this.loading = false;
      this.progressValue = 0;
      // redirect to payment
      this.router.navigate(['/post_info', postID]);
    }
  }


  private async uploadImages(postID, progressStep): Promise<void> {
    this.message = 'Subiendo foto de portada';

    // upload images to storage and get download links

    if (this.coverFoto) {
      this.myPost.picture = await this.db.uploadImage(postID, this.coverFoto, 'cover');
    }

    // tslint:disable-next-line: forin
    for (const i in this.files) {
      this.message = 'Subiendo foto #' + (+i + 1);
      const url = await this.db.uploadImage(postID, this.files[i], i.toString());
      this.progressValue += progressStep;
      (this.myPost as Single).pet.pictures![i] = url;
    }
  }

  private validate(): void {
    console.log('validating');
    console.log(this.petInfoForm);
    this.isValidForm = this.petInfoForm.valid;
    this.myPost.description = this.petInfoForm.controls.descriptionCtrl.value;
    this.myPost.animal = this.post.animal;
    this.myPost.pet.breed = this.petInfoForm.controls.breedCtrl.value;
    this.myPost.pet.vaccines = this.selectedVaccines;
    this.myPost.pet.price = this.petInfoForm.controls.priceCtrl.value;
    console.log(this.files);
    this.myPost.pet.pictures?.forEach((pic, i) => {
      if (this.files[i]) {
        console.log('replacing');
        this.myPost.pet.pictures![i] = this.petImages[i] as string;
      }
    });
  }

  ngOnInit(): void {
    console.log(this.post);
    console.log(this.petInfoForm);
    this.petInfoForm.controls.birthCtrl.setValue(this.post.pet.birthDate);
    this.petInfoForm.controls.descriptionCtrl.setValue(this.post.description);
    this.petInfoForm.controls.vaccineCtrl.setValue(this.post.pet.vaccines);
    this.petInfoForm.controls.breedCtrl.setValue(this.post.pet.breed);
    this.petInfoForm.controls.priceCtrl.setValue(this.post.price);

    this.selectedVaccines = this.post.pet.vaccines;
    this.petImages = this.post.pet.pictures!;
    // subscribe for petInfoForm changes
    this.petInfoForm.valueChanges.subscribe(x => {
      this.validate();
    });
    this.myPost = Object.assign({}, this.post);

  }

}
