import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation, NgxGalleryImageSize } from 'ngx-gallery-9';
import { Post, Single } from 'src/app/models/post';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { DatabaseServiceService } from 'src/app/services/database-service.service';

@Component({
  selector: 'app-post-info',
  templateUrl: './post-info.component.html',
  styleUrls: ['./post-info.component.scss']
})
export class PostInfoComponent implements OnInit {

  public loading = false;
  public creator: User;
  public post: Post;
  public user: User;

  public postID: string;
  constructor(private route: ActivatedRoute, private db: DatabaseServiceService, private router: Router, private auth: AuthService) {
    this.auth.user$.subscribe(u => {
      this.user = u;
      // console.log(this.user);

    });
  }


  private async addSeenToPost(): Promise<void> {
    await this.db.addSeen(this.post.id);
  }

  public async toggleFavorite(val: boolean): Promise<void> {
    if (val) {
      await this.setAsFavourite();
    }
    else {
      await this.setAsNotFavorite();
    }
  }

  public async setAsNotFavorite(): Promise<void> {
    this.loading = true;
    await this.db.setAsNotFavorite(this.post.id, this.user.uid);
    this.loading = false;
  }

  public async setAsFavourite(): Promise<void> {
    this.loading = true;
    // user is logged in
    if (this.user !== null) {
      await this.db.setAsFavourite(this.post.id, this.user.uid);
      this.loading = false;
    }
    else {
      this.loading = false;
      this.router.navigate(['/login']);
    }
  }

  private async getPost(): Promise<void> {

    this.loading = true;
    this.post = await this.db.getPostFromID(this.postID) as Single;

    console.log('post: ', this.post);
    if (this.post === undefined) {
      this.router.navigate(['/post_not_found']);
    }
    const creator = await this.db.getUserFromID(this.post.creator);
    this.creator = creator;
    this.loading = false;


    // check if the user has seen this post before
    let seen: any = localStorage.getItem(`seenPosts-${this.user.uid}`);
    if (seen === null) {
      localStorage.setItem(`seenPosts-${this.user.uid}`, JSON.stringify([this.postID]));
      this.addSeenToPost();
    }
    else {
      seen = JSON.parse(seen!);
      if (seen.indexOf(this.postID) === -1) {
        this.addSeenToPost();
        seen!.push(this.postID);
      }
      localStorage.setItem(`seenPosts-${this.user.uid}`, JSON.stringify(seen));
    }
  }

  ngOnInit(): void {
    this.route.params.subscribe(async params => {
      this.postID = params.id;
      await this.getPost();
    });
  }

}
