import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-catdog-field',
  templateUrl: './catdog-field.component.html',
  styleUrls: ['./catdog-field.component.scss']
})
export class CatdogFieldComponent implements OnInit {

  @Input() formControl: FormControl = new FormControl();
  public animals = [{ value: 'dog', label: 'Perro' }, { value: 'cat', label: 'Gato' }];
  constructor() { }

  ngOnInit(): void {
  }

}
