<form fxFlex="100%" fxLayout="column" class="form-container" [formGroup]="petInfoForm">
    <ng-template matStepLabel>informacion basica</ng-template>

    <h1>Información de la camada</h1>
    <p>Texto explicando que en esta parte es la informacion general de la camada</p>
    <div fxLayout.gt-sm="row" fxLayout="column" fxLayoutAlign="space-between stretch">

        <div fxLayout="column" fxLayoutAlign="space-between stretch" fxFlex="45%">
            <app-breed-autocomplete [animal]="animal" [formControl]="breedCtrl" style="width: 100%;"
                [cualquiera]="false" (breedChange)="breedCtrl.setValue($event)">
            </app-breed-autocomplete>
            <div *ngIf="petInfoForm.get('breedCtrl')!.errors && 
                                    (petInfoForm.get('breedCtrl')!.dirty || petInfoForm.get('breedCtrl')!.touched)">
                <small class="text-danger" *ngIf="petInfoForm.get('breedCtrl')!.hasError('required')">
                    Este campo es requerido
                </small>
                <small class="text-danger" *ngIf="petInfoForm.get('breedCtrl')!.hasError('invalid')!">
                    Debe seleccionar una raza que se encuentre en el listado.
                </small>
            </div>

            <mat-form-field appearance="outline">
                <mat-label>Fecha de nacimiento</mat-label>
                <input matInput [matDatepicker]="picker" formControlName="birthCtrl" name="birthDate">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>

            <!--Vacunas-->
            <mat-form-field class="example-chip-list" appearance="outline">
                <mat-label>Vacunas</mat-label>
                <mat-chip-list #chipList>
                    <mat-chip *ngFor="let vaccine of selectedVaccines" (removed)="remove(vaccine)">
                        {{vaccine}}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                    <input placeholder="Otra vacuna..." #vaccineInput formControlName="vaccineCtrl"
                        [matAutocomplete]="auto" [matChipInputFor]="chipList"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="add($event)">
                </mat-chip-list>
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                    <mat-option *ngFor="let vaccine of filteredVaccines | async" [value]="vaccine">
                        {{vaccine}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>


            <mat-radio-group aria-label="Select an option" fxLayout="column" class="radio-group"
                [(ngModel)]="isAdoption">
                <mat-radio-button (click)="adopt()" [checked]="true" [value]="true" class="radio">
                    Poner en adopción
                </mat-radio-button>
                <mat-radio-button (click)="sell()" [value]="false" class="radio">
                    Vender
                </mat-radio-button>
            </mat-radio-group>
        </div>

        <div fxLayout="column" fxLayoutAlign="space-around stretch" fxFlex="45%">

            <mat-form-field appearance="outline" style="height: 100%;">
                <mat-label>Descripción</mat-label>
                <textarea matInput formControlName="descriptionCtrl" name="description" style="height: 100%;"
                    rows="20"></textarea>
            </mat-form-field>
        </div>
    </div>

    <h2>Información de las crias</h2>
    <p> Texto explicando que aca es la informacion de cada perrito</p>

    <div fxLayout="column" fxLayoutAlign="space-between stretch">
        <div *ngFor="let item of [].constructor(numberOfChildren); let i = index" fxLayout="row"
            fxLayoutAlign="space-around stretch">
            <div fxLayout="column" fxLayoutAlign="center start">
                <mat-form-field appearance="outline" class="child-info-field" class="with-padding">
                    <mat-label>Sexo</mat-label>
                    <mat-select [formControl]="$any(children[i].controls.sexCtrl)" name="sex">
                        <mat-option value="male">
                            Macho
                        </mat-option>

                        <mat-option value="female">
                            Hembra
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field *ngIf="!isAdoption" appearance="outline" class="child-info-field" class="with-padding">
                    <mat-label>Precio</mat-label>
                    <span matSuffix>COP &nbsp;</span>

                    <input matInput type="number" [formControl]="$any(children[i].controls.priceCtrl)"
                        name="description">
                </mat-form-field>
                <button mat-mini-fab color="warn" (click)="removeChild(i)" #tooltip="matTooltip"
                    matTooltip="Eliminar cria" [matTooltipPosition]="'right'" matTooltipHideDelay="1000"
                    class="remove-child-btn">
                    <mat-icon>delete</mat-icon>
                </button>
            </div>

            <div fxLayout="column" fxLayoutAlign="center center">
                <img *ngIf="petImages[i] != ''" [src]="petImages[i]" width="100">
                <button mat-stroked-button color="primary" type="button" (click)="coverFileInput.click()"
                    class="secondary-btn">
                    <span *ngIf="petImages[i] == '' && petImages[i] != undefined">Agregar foto</span>
                    <span *ngIf="!(petImages[i] == '' && petImages[i] != undefined)">Cambiar foto</span>

                    <input #coverFileInput type="file" accept=".jpg,.jpeg,.png" (change)="onSelectSingleFile($event, i)"
                        style="display:none;" [formControl]="$any(children[i].controls.imageCtrl)" />
                </button>
            </div>
        </div>

        <button type='button' mat-stroked-button class="secondary-btn" style="width: 200px;" (click)="addChild()">
            Agregar cria a la camada
        </button>
    </div>
</form>