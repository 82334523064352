<mat-toolbar color="primary" [ngClass]="{'thick-bar': large}">

  <button mat-icon-button [matMenuTriggerFor]="menu">
    <mat-icon>menu</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="navigateHome()">
      <mat-icon>home</mat-icon>
      <span>Página principal</span>
    </button>

    <button *ngIf="isSignedIn" mat-menu-item (click)="goToFavorites()">
      <mat-icon>favorite</mat-icon>
      <span>Mis favoritos</span>
    </button>

    <button mat-menu-item (click)="myPosts()">
      <mat-icon>assignment</mat-icon>
      <span>Mis publicaciones</span>
    </button>

    <button mat-menu-item (click)="createPost()">
      <mat-icon>pets</mat-icon>
      <span>Crear publicacion</span>
    </button>
  </mat-menu>


  <span (click)="navigateHome()" id="title">Sabueso</span>
  <span class="spacer"></span>


  <button *ngIf="isSignedIn" mat-icon-button [matMenuTriggerFor]="menuProfile">
    <mat-icon>account_circle</mat-icon>
  </button>
  <mat-menu #menuProfile="matMenu">

    <button *ngIf="isSignedIn" mat-menu-item (click)="goToProfile()">
      <mat-icon>account_circle</mat-icon>
      <span>Mi perfil</span>
    </button>

    <button *ngIf="isSignedIn" mat-menu-item (click)="signOut()">
      <mat-icon>cancel</mat-icon>
      <span>Cerrar sesion</span>
    </button>

  </mat-menu>

  <button *ngIf="!isSignedIn" mat-stroked-button (click)="signIn()">Inicia sesión</button>



</mat-toolbar>