import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { DatabaseServiceService } from 'src/app/services/database-service.service';
import { AuthService } from 'src/app/services/auth.service';
import { User } from 'src/app/models/user';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { PaymentService } from 'src/app/services/payment.service';
import { Post } from 'src/app/models/post';
import { AnalyticsService } from 'functions/services/analytics.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {


  postID: string;
  loading = false;
  promoCode = '';
  message = '';
  payingWithPromoCode = false;
  user: User;
  payUEndpoint;
  reference: string;
  price: number;
  showErrorMessage = false;

  payUInfo = {
    merchantId: '',
    accountId: '',
    description: '',
    referenceCode: '',
    amount: '100',
    tax: '0',
    taxReturnBase: '0',
    currency: 'COP',
    signature: '',
    test: '',
    buyerEmail: 'test@test.com',
    responseUrl: 'https://www.google.com?response=true',
    confirmationUrl: 'https://www.google.com?confirmation=true',

  };

  constructor(
    private route: ActivatedRoute,
    private db: DatabaseServiceService,
    private dialog: MatDialog,
    private router: Router,
    private snackBar: MatSnackBar,
    private analytics: AnalyticsService,
    private authService: AuthService,
    private payments: PaymentService
  ) {
    this.loading = true;

  }

  public selectPromoCode(): void {
    this.payingWithPromoCode = true;
  }

  public backToSelectPaymentMethod(): void {
    this.payingWithPromoCode = false;
  }

  public formatPromoCode(): void {
    console.log('promo: ', this.promoCode);
    this.promoCode = this.promoCode.toUpperCase();
    if (this.promoCode.length === 4 || this.promoCode.length === 9 || this.promoCode.length === 14) {
      this.promoCode += '-';
    }
  }

  public unformat(code: string): string {
    return code.split('-').join('');
  }

  public async payWithCode(): Promise<void> {
    const promoCode = this.unformat(this.promoCode);
    this.loading = true;
    console.log('code: ', promoCode);

    const ans = this.payments.usePromoCode(promoCode, this.postID, this.price).subscribe({
      next: async (res) => {
        console.log(res);
        const dialogRef = this.dialog.open(PaymentDialog);

        dialogRef.afterClosed().subscribe(() => {
          this.router.navigate(['/post_info', this.postID]);
        });
        this.loading = false;

        this.analytics.payWithCode(this.postID, promoCode);
        this.analytics.purchase(this.postID, this.price, promoCode);

      },
      error: async (err) => {
        console.log(err);
        this.loading = false;

        if (err.status === 403) {
          this.loading = false;
          this.openSnackBar('El código que acaba de ingresar no está disponible');
        }
        else {
          this.openSnackBar('Ocurrió un error, inténtalo más tarde');
        }
      },
      complete: async () => {
        console.log('complete');

      }
    });

  }

  private openSnackBar(message: string): void {
    this.snackBar.open(message, 'cerrar');
  }

  public openDialog(): void {
    console.log('antes de abrirlo');
    this.dialog.open(PaymentDialog);
    console.log('despues de cerrar');

  }

  ngOnInit(): void {
    this.loading = true;
    this.route.params.subscribe(async params => {
      this.postID = params.id;
      let post = await this.db.getPostFromID(this.postID);
      if (post === undefined) {
        this.router.navigate(['/post_not_found']);
      }
      post = post!;

      // check if the post is already paid

      if (post.active === true) {
        const paymentStatus = await this.payments.getPaymentStatus(this.postID);
        if (paymentStatus !== undefined) {
          console.log('post is already paid');
          this.router.navigate(['/post_info', this.postID]);
        }
      }
      // check if pay U did not send confirmation but the payment was done
      else {
        await this.checkPayment();
      }

      this.loading = false;

      if (post.litter === true) {
        this.price = 40000;
      }
      else {
        this.price = 15000;
      }
      this.loading = true;

      this.payUEndpoint = this.payments.payUEndpoint;
      this.reference = this.postID;


      this.authService.user$.subscribe(async u => {
        this.user = u;
        this.loading = false;
        this.payUInfo = await this.payments.getPayUInfo(this.price, this.reference, this.postID, this.user.email);
        console.log('payu info: ', this.payUInfo);
      });
    });
  }

  public logPayment(): void {

    this.analytics.purchase(this.postID, this.price);
  }


  private async checkPayment(): Promise<void> {
    try {
      const paymentInfo: any = await (await this.payments.getPayUConfirmation(this.postID)).toPromise();

      console.log('payment info: ', paymentInfo);
      if (paymentInfo.result.payload != null && paymentInfo.result.payload[0].status === 'CAPTURED') {
        const data = paymentInfo.result.payload[0];
        const confirmationData = {
          value: data.transactions[0].additionalValues.PM_PAYER_TOTAL_AMOUNT.value,
          payment_method: data.transactions[0].paymentMethod,
          reference_sale: data.referenceCode,
          currency: data.transactions[0].additionalValues.PM_PAYER_TOTAL_AMOUNT.currency,
          response_message_pol: data.transactions[0].transactionResponse.responseMessage,
          sign: data.transactions[0].extraParameters.SIGNATURE,
        };

        console.log('confirmationData: ', confirmationData);
        // IF payment was done, then activate the post
        // TODO: the payment confirmation was not sent by payU
        // const answer = await this.payments.sendPaymentConfirmation(confirmationData).toPromise();

        // console.log('answer: ', answer);
      }
    }
    catch (err) {
      this.showErrorMessage = true;
      this.openSnackBar('No se puede verificar el estado del pago en este momento. Inténtalo más tarde.');
      console.log(err);
    }
  }

}

@Component({
  selector: 'payment-dialog',
  templateUrl: 'payment-dialog.html',
})
export class PaymentDialog {

  constructor(
    public dialogRef: MatDialogRef<PaymentDialog>) { }

  public close(): void {
    this.dialogRef.close();
  }
}
