<mat-form-field class="example-full-width" appearance="outline" style="width: 100%;">
    <mat-label>Cuidad</mat-label>
    <input type="text" placeholder="Pick one" aria-label="Number" matInput [formControl]="cityControl"
        style="width: 100%;" [matAutocomplete]="autoGroup">
    <mat-icon matSuffix>location_city</mat-icon>

    <mat-autocomplete autoActiveFirstOption #autoGroup="matAutocomplete">
        <mat-optgroup *ngFor="let group of citiesOptions | async" [label]="group.departamento">
            <mat-option *ngFor="let option of group.ciudades" [value]="option">
                {{option}}
            </mat-option>
        </mat-optgroup>
    </mat-autocomplete>
</mat-form-field>