<div [ngClass]="{'blured': loading}" fxLayout="column" fxLayoutAlign="start center">


    <!--LOADING SPINNER-->
    <div *ngIf="loading" class="loading-area" fxLayout="row" fxLayoutAlign="center center">
        <mat-spinner></mat-spinner>
    </div>
    <app-nav-bar fxFlex="100%" style="width: 100%;"></app-nav-bar>


    <div fxFlex="100%" fxLayout="column" fxLayoutAlign="center center" style="margin-bottom: 50px;"
        id="farm-background">
        <div fxLayout="column" fxLayoutAlign="space-between strerch" class="box_container">
            <div fxLayoutAlign="center center">
                <h1>Encuentra aquí tu próxima mascota</h1>
            </div>

            <div fxLayout="column">
                <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-between stretch">
                    <app-catdog-field fxFlex.lt-sm="100%" [formControl]="animalCtrl" name="animal"></app-catdog-field>
                    <app-breed-autocomplete [animal]="animalCtrl.value" (breedChange)="changingBreed($event)"
                        [cualquiera]="true" [breedFormControl]="breedCtrl" name="breed" fxFlex="30%"
                        fxFlex.lt-sm="100%">
                    </app-breed-autocomplete>
                    <app-age-field fxFlex.lt-sm="100%"></app-age-field>
                </div>
                <div fxLayoutAlign="center center">

                    <button mat-raised-button color="primary" fxFlex.lt-md="100%" style="width: 30%"
                        (click)="search()">Buscar</button>
                </div>
            </div>
        </div>
    </div>

    <div fxFlex="90%" fxLayout="column" fxLayout.lt-sm="row">
        <!--perros-->
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="start center">
            <div *ngFor="let img of dogsImageArray" class="main-card" fxLayoutAlign="center center">
                <img class="main-card-image" [src]="img" width="120px" height="120px">
            </div>
        </div>

        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="start center">
            <div *ngFor="let img of catsImageArray" class="main-card" fxLayoutAlign="center center">
                <img class="main-card-image" [src]="img" width="120px" height="120px">
            </div>

        </div>
    </div>

</div>