import { Injectable } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { AuthService } from 'src/app/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(private analytics: AngularFireAnalytics, private auth: AuthService) { }

  private async getUserId(): Promise<string> {
    return (await this.auth.user$.toPromise()).uid;
  }

  public async setAsFavorite(postId: string): Promise<void> {
    const userID = await this.getUserId();
    this.analytics.logEvent('set_as_favorite', {
      post_id: postId,
      user: userID
    });
  }

  public async setAsNotFavorite(postId: string): Promise<void> {
    const userID = await this.getUserId();
    this.analytics.logEvent('set_as_not_favorite', {
      post_id: postId,
      user: userID
    });
  }


  public async payWithCode(postID, code): Promise<void> {
    const userID = await this.getUserId();
    this.analytics.logEvent('pay_with_code', {
      post_id: postID,
      promo_code: code,
      user: userID
    });
  }

  public async purchase(postID: string, price: number, promoCode?: string): Promise<void> {
    const userID = await this.getUserId();

    if (promoCode !== undefined) {
      this.analytics.logEvent('purchase', {
        transaction_id: postID,
        currency: 'COP',
        value: price,
        coupon: promoCode,
      });
    } else {
      this.analytics.logEvent('purchase', {
        transaction_id: postID,
        currency: 'COP',
        value: price,
      });
    }
  }


  public async contactSeller(postID: string, sellerID: string): Promise<void> {
    this.analytics.logEvent('contact_seller', {
      post_id: postID,
      seller_id: sellerID,
    });
  }

  public searchBreed(animal: string, breed: string): void {
    this.analytics.logEvent('search_breed', {
      animal,
      breed,
    });

    // this is the default google analytics tag for a search.
    // including it here so we can compare the results with the custom event
    this.analytics.logEvent('search', {
      breed,
    });
  }

  public login(method: string): void {
    this.analytics.logEvent('login', {
      method
    });
  }

  public register(method: string): void {
    this.analytics.logEvent('sign_up', {
      method
    });
  }

  public beginCheckout(postID: string, price: number, promoCode?: string): void {

  }

}
