import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Post, Single } from 'src/app/models/post';
import { AngularFireAnalytics } from '@angular/fire/analytics';

@Component({
  selector: 'app-post-card',
  templateUrl: './post-card.component.html',
  styleUrls: ['./post-card.component.scss']
})
export class PostCardComponent implements OnInit {

  @Output() selected = new EventEmitter<void>();
  @Output() favourite = new EventEmitter<void>();
  @Output() eliminateFavorite = new EventEmitter<void>();



  @Input() isFavorite: boolean;
  @Input() post: any = {
    active: true,
    creationDate: new Date(),
    description: 'Lorem Ipsum sdaf asdf lkan dqui hfuihe uf asuidf asd fasd fas. asdl asdhjlfgasudfg asg as dgasjl iqweuis djbyui sjkdhf as.',
    litter: false,
    views: 10,
    type: 'sell',
    price: 1000,
    pet: {
      name: 'Chanda',
      sex: 'female',
      certificates: [],
      vaccines: ['rabia', 'pulgas'],
      breed: 'Airdale terrier',
      birthDate: new Date()
    }


  } as unknown as Single;
  constructor(private analytics: AngularFireAnalytics) {
  }

  setAsFavourite(): void {
    this.favourite.emit();
  }

  setAsNotFavorite(): void {
    this.eliminateFavorite.emit();
  }


  goToPost(): void {
    this.selected.emit();
  }

  ngOnInit(): void {

  }

}
