<div [ngClass]="{'blured': loading}" fxLayout="column">


    <!--LOADING SPINNER-->
    <div *ngIf="loading" class="loading-area" fxLayout="row" fxLayoutAlign="center center">
        <mat-spinner></mat-spinner>
    </div>
    <app-nav-bar></app-nav-bar>


    <div class="container">

        <!--FILTROS-->
        <app-search class="search-area" fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="center start"
            (filters)="search($event)">
        </app-search>

        <div fxLayout="row" fxLayoutAlign="end center">
            <span>Ordenar por:</span>
            <mat-button-toggle-group name="sort">
                <mat-button-toggle value="relevance" (change)="onValChange($event.value)">Relevancia</mat-button-toggle>
                <mat-button-toggle value="priceAscending" (change)="onValChange($event.value)">Precio (menor a mayor)
                </mat-button-toggle>
                <mat-button-toggle value="priceDescending" (change)="onValChange($event.value)">Precio (mayor a menor)
                </mat-button-toggle>

            </mat-button-toggle-group>
        </div>

        <!--POSTS-->
        <div fxLayout="row wrap" fxLayoutGap="16px grid" class="container">

            <div fxFlex="25%" fxFlex.xs="90%" fxFlex.sm="50%" *ngFor="let post of posts">
                <app-post-card [isFavorite]="isFavorite(post.id)" [post]="post"
                    (eliminateFavorite)="setAsNotFavorite(post.id)" (favourite)="setAsFavourite(post.id)"
                    (selected)="goToPost(post.id)"></app-post-card>
            </div>
        </div>
    </div>
</div>