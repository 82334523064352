<div class="city-dialog-container" fxLayout="column">
    <h1>Agregar ciudad</h1>
    <form class="example-form" fxFlex="100%">
        <app-city-field [formControl]="cityControl" (cityChange)="selectedCity = $event"></app-city-field>
    </form>
</div>

<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancelar</button>
    <button mat-button color="primary" [mat-dialog-close]="selectedCity" cdkFocusInitial>Ok</button>
</div>