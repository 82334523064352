import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  private data$: Observable<any>;
  private breeds$: Observable<any>;

  constructor(private firestore: AngularFirestore) {
    // web page data
    this.data$ = this.firestore.collection('data').doc('web-page-data').get();

    // breeds data
    this.breeds$ = this.firestore.collection('data').doc('breeds').get();

  }


  // IMPROVE: this could be a set instead of an array, it would make search faster on search-results-component
  public async getDogBreeds(): Promise<string[]> {
    const breedsInfo = (await this.breeds$.toPromise()).data().dogs;
    const ans: string[] = [];
    breedsInfo.forEach((element) => {
      ans.push(element.breed);
    });

    return ans;
  }

  public async getCatBreeds(): Promise<string[]> {
    const breedsInfo = (await this.breeds$.toPromise()).data().cats;
    const ans: string[] = [];
    breedsInfo.forEach((element) => {
      ans.push(element.breed);
    });

    return ans;
  }

  public async getDogVaccines(): Promise<string[]> {
    return (await this.data$.toPromise()).data().dogVaccines;
  }

  public async getTermsAndConditions(): Promise<string> {
    return (await this.data$.toPromise()).data().termsAndConditions;
  }

  public async getFAQ(): Promise<any> {
    return (await this.data$.toPromise()).data().FAQ;
  }

  public async getPrivacyAgreement(): Promise<string> {
    return (await this.data$.toPromise()).data().privacyAgreement;

  }

  public async getDogsBreedsInfo(): Promise<any[]> {
    return (await this.breeds$.toPromise()).data().dogs;
  }

  public async getCatsBreedsInfo(): Promise<any[]> {
    return (await this.breeds$.toPromise()).data().cats;
  }

  public getCommonCities(): string[] {
    return ['Bogotá', 'Medellin', 'Barranquilla'];
  }

  public getCommonDogBreeds(): string[] {
    return ['Golden retriever', 'Labrador', 'Dalmata'];
  }

  public getCommonCatBreeds(): string[] {
    return ['Persa', 'Angora', 'Siames', 'Siberiano'];
  }
}
