import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Filters } from 'src/app/models/filters';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {

  public breedCtrl = new FormControl(undefined, []);
  public animalCtrl = new FormControl(undefined, [Validators.required]);

  public loading = false;
  constructor(private router: Router, private snackBar: MatSnackBar) { }

  // TODO: real images
  public iconSrc = '/assets/icons/Icono_perro.png';
  public src1 = 'https://i.natgeofe.com/n/4f5aaece-3300-41a4-b2a8-ed2708a0a27c/domestic-dog_thumb_3x4.jpg';
  public src2 = 'https://media.istockphoto.com/photos/pug-sitting-against-white-background-picture-id1154953221';
  public src3 = 'https://merriam-webster.com/assets/mw/images/article/art-wap-landing-mp-lg/dog-whistle-2450-ae2dc5dad5c3665bab90f51613c230a7@1x.jpg';

  public dogsImageArray = [this.iconSrc, this.src1, this.src2, this.src3];

  public catsImageArray = ['/assets/icons/Icono_gato.png',
    'https://upload.wikimedia.org/wikipedia/commons/6/69/June_odd-eyed-cat_cropped.jpg',
    'https://images-na.ssl-images-amazon.com/images/I/71+mDoHG4mL.png',
    'https://s36537.pcdn.co/wp-content/uploads/2018/01/An-orange-tabby-cat-with-the-M-marking-on-the-forehead.jpg.optimal.jpg'];


  ngOnInit(): void {
  }

  changingBreed(breed): void {
    console.log(breed);
  }

  public search(): void {
    this.animalCtrl.markAsTouched();
    if (this.animalCtrl.value === null) {
      this.openSnackBar('Por favor selecciona un tipo de mascota primero.');
      return;
    }
    let filters: any = { animal: this.animalCtrl.value };

    if (this.breedCtrl.value !== '' && this.breedCtrl.value !== 'cualquiera') {
      filters = { animal: this.animalCtrl.value, breed: JSON.stringify([this.breedCtrl.value]) };
    }
    this.router.navigate(['/search'], { queryParams: filters });
  }

  private openSnackBar(message: string): void {
    this.snackBar.open(message, 'cerrar');
  }
}
