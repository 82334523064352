import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CreatePostComponent } from './components/create-post/create-post.component';
import { EditPostComponent } from './components/edit-post/edit-post.component';
import { EditProfileComponent } from './components/edit-profile/edit-profile.component';
import { FavoritesComponent } from './components/favorites/favorites.component';
import { GenerateCodesComponent } from './components/generate-codes/generate-codes.component';
import { HelpComponent } from './components/help/help.component';
import { LandingComponent } from './components/landing/landing.component';
import { LoginComponent } from './components/login/login.component';
import { MyPostsComponent } from './components/my-posts/my-posts.component';
import { PaymentComponent } from './components/payment/payment.component';
import { PostInfoComponent } from './components/post-info/post-info.component';
import { PostListComponent } from './components/post-list/post-list.component';
import { SearchResultsComponent } from './components/search-results/search-results.component';
import { TermsComponent } from './components/terms/terms.component';
import { AdminGuard } from './services/admin.guard';
import { AuthGuard } from './services/auth.guard';
import { PostNotFoundComponent } from './components/post-not-found/post-not-found.component';

const routes: Routes = [
  { path: 'create_post', component: CreatePostComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: '', redirectTo: 'landing', pathMatch: 'full' },
  { path: 'post_list', component: PostListComponent },
  { path: 'post_info/:id', component: PostInfoComponent },
  { path: 'pay/:id', component: PaymentComponent },
  { path: 'favorites', component: FavoritesComponent, canActivate: [AuthGuard] },
  { path: 'my_posts', component: MyPostsComponent, canActivate: [AuthGuard] },
  { path: 'generate_codes', component: GenerateCodesComponent, canActivate: [AdminGuard] },
  { path: 'search', component: SearchResultsComponent },
  { path: 'profile', component: EditProfileComponent },
  { path: 'terms', component: TermsComponent },
  { path: 'help', component: HelpComponent },
  { path: 'landing', component: LandingComponent },
  { path: 'edit/:id', component: EditPostComponent },
  { path: 'post_not_found', component: PostNotFoundComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
