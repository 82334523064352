import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Post } from 'src/app/models/post';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { DatabaseServiceService } from 'src/app/services/database-service.service';

@Component({
  selector: 'app-my-posts',
  templateUrl: './my-posts.component.html',
  styleUrls: ['./my-posts.component.scss']
})
export class MyPostsComponent implements OnInit {

  public loading = false;
  private user: User;
  public myPosts: Post[];
  public message = '';

  constructor(private auth: AuthService, private db: DatabaseServiceService, private router: Router, private dialog: MatDialog) {
    auth.user$.subscribe(async u => {
      this.user = u;
      console.log(this.user);
      this.loading = true;
      this.myPosts = await this.getMyPosts();
      console.log(this.myPosts);
      this.loading = false;
    });
  }

  private async getMyPosts(): Promise<Post[]> {
    return await this.db.getMyPosts(this.user);
  }

  public payPost(postID: string): void {
    this.router.navigate(['/pay', postID]);
  }

  public editPost(postID: string): void {
    console.log('editting');
    this.router.navigate(['/edit', postID]);
  }

  openDialog(postID: string): void {
    const dialogRef = this.dialog.open(EndPostDialogComponent);

    dialogRef.afterClosed().subscribe(async result => {
      // end post
      console.log(result);
      if (result === true) {
        this.loading = true;
        try {
          console.log('anding post');
          const ans = await this.db.endPost(postID);
          console.log(ans);
        } catch (error) {
          console.log(error);
          this.message = 'Hubo un error, intentelo mas tarde';
        }
        this.loading = false;
      } // cancel
      else {

      }
    });
  }

  ngOnInit(): void {
  }

}



@Component({
  selector: 'app-end-post-dialog',
  templateUrl: 'end-post-dialog.html',
})
export class EndPostDialogComponent { }
